import { ExamIncidenceType } from '../channels/exam_incidences_channel';
import { ID } from '../types';

import client from './client';

type CreateParams = {
  examId: ID,
  type: ExamIncidenceType
}
// TODO: change url to new backend
export const create = async (data: CreateParams): Promise<void> => {
  const postData = {
    exam_incidence: {
      incidence_type: data.type,
    },
  };

  const baseurl = window.location.href.indexOf('demo') !== -1 ? '/demo' : '';

  const response = await client.post(
    `${baseurl}/exams/${data.examId}/incidences`,
    postData,
  );

  return response.data;
};

const IncidenceService = {
  create,
}

export default IncidenceService;