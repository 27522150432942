import { UID } from 'agora-rtc-sdk-ng';
import _ from 'lodash/fp';
import React from 'react';

import { ExamsMessage } from '../../../../types';
import LocalMessage from '../LocalMessage/LocalMessage';
import OtherMessage from '../OtherMessage/OtherMessage';

type Props = {
  message: ExamsMessage
  localUid: UID
}

const Message: React.FunctionComponent<Props> = ({ message, localUid }: Props) => (
  <div className="flex flex-col">
    {_.isEqual(message.senderId, localUid)
      ? <LocalMessage message={message} />
      : <OtherMessage message={message} />}
  </div>
);

export default Message;
