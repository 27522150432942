import React from 'react';

type Props = {
  name: string
}

const ExamName: React.FunctionComponent<Props> = ({ name }: Props) => (
  <div className="font-bold text-white self-end">
    {name}
  </div>
);

export default ExamName;
