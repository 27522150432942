import React, { useEffect, useState } from 'react';

import { useRoomStore } from '../../../../../hooks/store';
import client from '../../../../../services/client';
import { ExamsUser } from '../../../../../types';
import IdentityBlock from '../../../../IdentityValidation/IdentityBlock';
import UserPlayer from '../../../../Players/UserPlayer/UserPlayer';
import RoundedCard from '../../../../RoundedCard/RoundedCard';

import styles from './OneOnOneRender.module.scss';

type Props = {
  user: ExamsUser;
};

type DocCheck = {
  extracted: Record<string, unknown>;
  documentDataChecking: Array<{ ocr: string, category: string }>;
  status: string;
};
type IdentityDataInterface = {
  docCheck: DocCheck;
  docImg: string;
  faceMatchingImg: string;
  overallResult?: {
    status: 'declined' | 'approved',
    comments: Array<{
      service: 'liveness' | 'doc-check' | 'face-matching',
      status: 'approved' | 'declined',
      comment: string
    }>,
  }
};

const OneOnOneRender: React.FunctionComponent<Props> = ({ user }: Props) => {
  const [identityData, setIdentityData] = useState<IdentityDataInterface>({
    docCheck: { extracted: {}, documentDataChecking: [], status: 'denied' },
    docImg: '',
    faceMatchingImg: '',
  });
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const roomStore = useRoomStore();
  const { identityRegistration } = roomStore.info.exam;

  useEffect(() => {
    if (identityRegistration) {
      client
        .post('/api/registration/result_by_exam', { username: user.username, exam: roomStore.info.exam.id })
        .then((response) => {
          const { data } = response;
          if (data.success) {
            setIdentityData({
              docCheck: data.docCheck,
              docImg: data.docImg,
              faceMatchingImg: data.faceMatchingImg,
              overallResult: data.overallResult,
            });
            setSuccess(true);
          }
          setLoading(false);
        });
    }
  }, [roomStore.info.exam.id, user, identityRegistration]);

  return (
    <div className={styles.grid_wrapper}>
      <div className={identityRegistration ? styles.viewer : styles.viewer_full}>
        <RoundedCard className="h-full">
          <UserPlayer user={user} className="h-full" focus="EXAM" object_fit_contain />
        </RoundedCard>
      </div>
      <div className={identityRegistration ? styles.viewer : styles.viewer_full}>
        <RoundedCard className="h-full">
          <UserPlayer
            user={user}
            className="h-full"
            hideName
            focus="PARTICIPANT"
            object_fit_contain
          />
        </RoundedCard>
      </div>
      {
        identityRegistration
          ? (
            <>
              <div className={styles.identity}>
                <RoundedCard className="h-full">
                  {loading ? null : (
                    <IdentityBlock
                      overallResult={identityData.overallResult}
                      docCheck={identityData.docCheck}
                      username={user.username}
                    />
                  )}
                </RoundedCard>
              </div>
              <div className={styles.photo}>
                <RoundedCard className="h-full">
                  {!success ? null : (
                    <img src={identityData.faceMatchingImg} alt="img" />
                  )}
                </RoundedCard>
              </div>
              <div className={styles.photo}>
                <RoundedCard className="h-full">
                  {!success ? null : (
                    <img src={identityData.docImg} alt="img" />
                  )}
                </RoundedCard>
              </div>
            </>
          ) : null
      }
    </div>
  );
};

export default OneOnOneRender;
