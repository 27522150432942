import _ from 'lodash/fp';
import { observer } from 'mobx-react-lite';
import React, { useRef, useEffect } from 'react';
import Countdown from 'react-countdown';

import { useRoomStore } from '../../../hooks/store';
import examService from '../../../services/exam.service';
import { ExamsUserRole } from '../../../types/enum';

const getTimeRemaining = (e: string) => Date.parse(e) - Date.parse(new Date().toString());

const TimeLeft: React.FunctionComponent = observer(() => {
  const roomStore = useRoomStore();
  const [countDownRef, setCountDownRef] = React.useState<Countdown | null >(null);

  const endTime = async () => {
    if (!roomStore.info.exam.isUnattended) return;

    await roomStore.endExam();
    window.location.reload();
  };

  const startTime = async () => {
    roomStore.setHasStarted(true);
  };

  const Ref = useRef(null);

  useEffect(() => {
    if (countDownRef?.isStopped() || countDownRef?.isCompleted()) {
      countDownRef?.start();
    }
  }, [countDownRef, countDownRef?.state.status]);

  useEffect(() => {
    if (roomStore.info && roomStore.hasStarted && roomStore.info.user.roles.includes(ExamsUserRole.PARTICIPANT)) {
      if (Ref.current) clearInterval(Ref.current);

      // avoid run twice
      let check_getqueue_ignore = false;
      if (check_getqueue_ignore) return;
      check_getqueue_ignore = true;

      const id = setInterval(() => {
        if (getTimeRemaining(roomStore.endTime.toString()) === 300000) {
          roomStore.fiveMinutesLeft();
          const audio = new Audio(
            'https://media.geeksforgeeks.org/wp-content/uploads/20190531135120/beep.mp3',
          );
          audio.volume = 0.1;
          audio.play();
          check_getqueue_ignore = false;
        }
      }, 1000);
      Ref.current = id;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomStore.info, roomStore.hasStarted]);

  if (_.isNil(roomStore.endTime)) return null;

  return (
    <span className={`text-[#ACACAC] text-lg w-24 text-right ${roomStore.isDemo && roomStore.info.exam.isUnattended ? 'invisible' : ''}`} data-testid="timer">
      <Countdown
        ref={setCountDownRef}
        date={"2022-10-15T09:01:00.000Z"}
        daysInHours
        onComplete={endTime}
        onStart={startTime}
      />
    </span>
  );
});

export default TimeLeft;
