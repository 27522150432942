// FIXME: Pendent to fix ts checks
// @ts-nocheck
import {
  action, computed, makeObservable, observable,
} from 'mobx';
import _ from 'lodash/fp';

import { Resetable } from '../../interfaces/resetable';
import type { ExamsUser, RoomExam } from '../../../types';
import ExamsLogger from '../../../logger';
import examService from '../../../services/exam.service';
import incidenceService from '../../../services/incidence.service';

import UserStore from './user';

import RoomStore from '.';

const logPrefix = '[Sentiment Store]';

class SentimentStore implements Resetable {
  roomStore!: RoomStore

  @observable
  loaded = false;

  @observable
  playing = false;

  @observable
  initCY: Promise<void> = null;

  constructor(room: RoomStore) {
    makeObservable(this);
    this.roomStore = room;
    this.appendScript();
  }

  get userStore(): UserStore {
    return this.roomStore.userStore;
  }

  @computed
  get localUser(): ExamsUser {
    return this.userStore.localUser;
  }

  @computed
  get exam(): RoomExam {
    return this.roomStore.info.exam;
  }

  @action
  reset(): void {
    this.initCY = null;
  }

  @action
  async stopAnalysis(): Promise<void> {
    if (_.isNil(this.initCY)) return;

    this.initCY.then(({ stop }:{ stop:Promise<void> }) => stop());
    this.reset();
  }

  @action
  async pausePlayAnalysis(evt: string): Promise<void> {
    if (_.isNil(this.initCY)) return;

    if (evt === 'pause' && this.playing) {
      this.initCY.then(({ stop }:{ stop:Promise<void> }) => stop());
      this.playing = false;
    }
    if (evt === 'play' && !this.playing) {
      this.initCY.then(({ start }:{ start:Promise<void> }) => start());
      this.playing = true;
    }
  }

  async appendScript(): Promise<void> {
    if (!this.loaded) {
      const script = document.createElement('script');
      script.src = 'https://ai-sdk.morphcast.com/v1.15/ai-sdk.js';
      script.async = true;
      document.body.appendChild(script);
      this.loaded = true;
    }
  }

  @action
  async sendAlert(evt: string): Promise<void> {
    incidenceService.create({
      examId: this.exam.id,
      type: evt,
    });
  }

  async initAnalysis(): Promise<void> {
    const id = this.userStore.localUser.videoTrack?.getTrackId();
    const vid = document.getElementById(`video_${id}`);
    const customSource = CY.createSource.fromVideoElement(vid);
    if (typeof CY === 'undefined' || customSource == null) {
      this.initAnalysis();
    }
    const cface = { multiFace: true };
    const calarmface = { timeWindowMs: 8000, initialToleranceMs: 10000, threshold: 0.75 };
    const calarmmorefaces = { timeWindowMs: 5000, initialToleranceMs: 10000, threshold: 0.33 };
    const calarmlowatt = { timeWindowMs: 30000, initialToleranceMs: 10000, threshold: 0.75 };

    this.initCY = CY.loader()
      .source(customSource)
      .licenseKey('c60d8cc6fc3e45e188bd4c3195e34441a3c4a72e5dfa')
      .addModule(CY.modules().FACE_DETECTOR.name, cface)
      .addModule(CY.modules().FACE_AGE.name)
      .addModule(CY.modules().FACE_AROUSAL_VALENCE)
      .addModule(CY.modules().FACE_GENDER.name)
      .addModule(CY.modules().FACE_EMOTION.name)
      .addModule(CY.modules().FACE_ATTENTION.name)
      .addModule(CY.modules().ALARM_NO_FACE.name, calarmface)
      .addModule(CY.modules().ALARM_MORE_FACES.name, calarmmorefaces)
      .addModule(CY.modules().ALARM_LOW_ATTENTION.name, calarmlowatt)
      .load();
  }

  throttle(func:() => void, wait: number): void {
    let timeout;
    return (...args) => {
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = null;
          func.apply(this, args);
        }, wait);
      }
    };
  }

  async analysisResult(type: string, value: string | number | number[]): void {
    await examService.saveAnalysis(this.exam.id, type, value);
  }

  @action
  async startAnalysis(): void {
    this.initAnalysis();
    this.initCY.then(({ start }:{ start:Promise<void> }) => {
      ExamsLogger.info(logPrefix, 'Starting...');
      this.playing = true;
      start();
    });

    const snt_enum = {
      undefined: 0, Angry: 1, Disgust: 2, Fear: 3, Happy: 4, Sad: 5, Surprise: 6, Neutral: 7,
    };
    const qrt_enum = {
      'High Control': 0, Obstructive: 1, 'Low Control': 2, Conductive: 3, Neutral: 4,
    };

    let snt_arr = []; let snt = 0;
    let att_arr = []; let att = 0;
    let qrt_arr = []; let qrt = 0;
    let no_face = true;
    let more_faces = true;
    let low_attention = true;
    const memory = 90000;

    if (this.exam.recognitionEnabled) {
      const ageListener = this.throttle((evt) => {
        this.analysisResult('sentiment_age', evt.detail.output.numericAge);
        window.removeEventListener(CY.modules().FACE_AGE.eventName, ageListener, true);
      }, 60000);
      window.addEventListener(CY.modules().FACE_AGE.eventName, ageListener, true);

      const genderListener = this.throttle((evt) => {
        this.analysisResult('sentiment_gender', evt.detail.output.mostConfident);
        window.removeEventListener(CY.modules().FACE_GENDER.eventName, genderListener);
      }, 60000);
      window.addEventListener(CY.modules().FACE_GENDER.eventName, genderListener);

      window.addEventListener(CY.modules().ALARM_NO_FACE.eventName, this.throttle((evt) => {
        if (evt.detail.output.noFace && no_face) {
          this.sendAlert('ABSENT_PARTICIPANT');
          no_face = false; low_attention = false;
          setTimeout(() => { low_attention = true; }, (memory / 3) + 1000);
          setTimeout(() => { no_face = true; }, memory);
        }
      }, 1000));
      window.addEventListener(CY.modules().ALARM_MORE_FACES.eventName, this.throttle((evt) => {
        if (evt.detail.output.moreFaces && more_faces) {
          this.sendAlert('PARTICIPANT_NOT_ALONE');
          more_faces = false;
          setTimeout(() => { more_faces = true; }, memory);
        }
      }, 1000));
    }

    if (this.exam.attentionEnabled) {
      window.addEventListener(CY.modules().FACE_ATTENTION.eventName, this.throttle((evt) => {
        att = parseInt(evt.detail.output.attention * 100, 10);
        att_arr.push(att);
        if (att_arr.length === 300) {
          this.analysisResult('sentiment_attention', att_arr);
          att_arr = [];
        }
      }, 5000));
      window.addEventListener(CY.modules().ALARM_LOW_ATTENTION.eventName, this.throttle((evt) => {
        if (evt.detail.output.lowAttention && low_attention) {
          this.sendAlert('DROP_LVL_ATTENTION');
          low_attention = false;
          setTimeout(() => { low_attention = true; }, memory);
        }
      }, 1000));
    }

    if (this.exam.sentimentEnabled) {
      window.addEventListener(CY.modules().FACE_EMOTION.eventName, this.throttle((evt) => {
        snt = evt.detail.output.dominantEmotion;
        snt_arr.push(snt_enum[snt]);
        if (snt_arr.length === 60) {
          this.analysisResult('sentiment_emotion', snt_arr);
          snt_arr = [];
        }
      }, 5000));
      window.addEventListener(CY.modules().FACE_AROUSAL_VALENCE.eventName, this.throttle((evt) => {
        qrt = evt.detail.output.quadrant;
        qrt_arr.push(qrt_enum[qrt]);
        if (qrt_arr.length === 60) {
          this.analysisResult('sentiment_quadrant', qrt_arr);
          qrt_arr = [];
        }
      }, 5000));
    }
  }
}

export default SentimentStore;
