import React from 'react';

export type Props = React.PropsWithChildren<{
  className?: string
  onClick?: () => void
  disabled?: boolean
}>

const Button: React.FunctionComponent<Props> = ({
  children, className = '', disabled = false, onClick,
}: Props) => (
  <button onClick={onClick} type="button" className={`font-bold py-2 px-6 rounded cursor-pointer focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed ${className}`} disabled={disabled}>
    {children}
  </button>
);

export default Button;
