import { ExamsCredentials } from "./stores/app";
import { RoomExam, RoomUser } from "./types";
import { ExamsUserRole } from "./types/enum";

export const ParticipantSample: RoomUser = {
  id: "2",
  name: "participant@wearexams.com",
  roles: [ExamsUserRole.PARTICIPANT],
  account: "c2c4599f-964f-440e-af04-ba88731a52a0&lti=1",
};

export const HostSample: RoomUser = {
  id: "1",
  name: "host@wearexams.com",
  roles: [ExamsUserRole.HOST],
  account: "c2c4599f-964f-440e-af04-ba88731a52a0&lti=1",
};

const quizzes = [
  {
    id: 211,
    name: "The Importance of Analytics in Marketing",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=21",
    start_date: "2023-07-16T19:20:15",
    end_date: "2023-07-17T19:20:15",
    timezone: "Europe/Madrid",
  },
  {
    id: 212,
    name: "Marketing Data Sources",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=25",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },
  {
    id: 213,
    name: "Marketing Measurement and Analytics Tools",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=26",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },
  {
    id: 214,
    name: "Data and Privacy",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=27",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },
  {
    id: 311,
    name: "Introduction and Descriptive Statistics for Exploring Data",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=33",
    start_date: "2023-07-16T19:20:15",
    end_date: "2023-07-17T19:20:15",
    timezone: "Europe/Madrid",
  },
  {
    id: 312,
    name: "Producing Data and Sampling",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=34",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },
  {
    id: 313,
    name: "Probability",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=35",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },
  {
    id: 314,
    name: "Normal Approximation and Binomial Distribution",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=36",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },
  {
    id: 315,
    name: "Sampling Distributions and the Central Limit Theorem",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=37",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },

  {
    id: 316,
    name: "Regression",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=38",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },
  {
    id: 317,
    name: "Confidence Intervals",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=39",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },
  {
    id: 318,
    name: "Tests of Significance",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=40",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },

  {
    id: 319,
    name: "Resampling",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=41",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },

  {
    id: 320,
    name: "Analysis of Categorical Data",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=42",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },

  {
    id: 321,
    name: "One-Way Analysis of Variance (ANOVA)",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=43",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },
  {
    id: 322,
    name: "Multiple Comparisons",
    url: "https://weareexams.moodlecloud.com/mod/quiz/view.php?id=44",
    start_date: "2022-12-16T19:20:15",
    end_date: "2022-12-16T19:22:15",
    timezone: "Europe/Madrid",
  },
];

export const ExamSample: (id: string) => RoomExam = (id) => {
  const quiz = quizzes.find(q => q.id === parseInt(id))
  
  return {
    id: "8c2059d7-2da7-46d1-b33b-c54e294f32eb",
    agoraId: 1,
    name: quiz.name,
    duration: 50,
    endTime: null,
    lmsURL: quiz.url,
    recognitionEnabled: true,
    isUnattended: false,
    identityRegistration: false,
    sentimentEnabled: true,
    recordEnabled: false,
    attentionEnabled: true,
    hasStarted: false,
    takeScreenshot: true,
    startDate: quiz.start_date,
    disableAudio: false,
  }
};

export const ExamCredentials: ExamsCredentials = {
  agora: { appId: "5ca93ee9f17047dd85afc9b1c8b7da0a" },
};
