import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import { ExamSample, HostSample, ParticipantSample } from "../../../factories";

// TODO: Role authorization
export default function Protected() {
  const location = useLocation();
  const hostPath = location.pathname.includes('requirements') || location.pathname.includes('start')
  const search = useQuery();
  const role = search.get('role')
  const quizId = search.get('quizId')
  const exam = ExamSample(quizId);
    if (role === 'host' && !hostPath ) {
      return <Navigate to={`/exams/${exam.id}/requirements?role=${role}&quizId=${quizId}`} />;
    }

    return (
      <Outlet
        context={{
          exam: exam,
          user: role === 'host' ? HostSample : ParticipantSample,
        }}
      />
    );
}
