import _ from 'lodash/fp';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { useRoomStore, useUserStore } from '../../../hooks/store';
import incidenceService from '../../../services/incidence.service';
import examService from '../../../services/exam.service';
import UserPlayer from '../../Players/UserPlayer/UserPlayer';
import RoundedCard from '../../RoundedCard/RoundedCard';

import Exam from './Exam/Exam';

const ParticipantCall: React.FunctionComponent = observer(() => {
  const userStore = useUserStore();
  const roomStore = useRoomStore();

  const onBeforeunload = async () => {
    await incidenceService.create({
      examId: roomStore.info.exam.id,
      type: 'USER_DROPPED',
    });
  };

  const handleTabClosing = async () => {
    if (roomStore.info.exam.isDemo) {
      await examService.abandonDemo(roomStore.info.exam.id);
    }
  };

  useEffect(
    () => {
      window.addEventListener('beforeunload', onBeforeunload);
      window.addEventListener('unload', handleTabClosing);
      return () => {
        window.removeEventListener('beforeunload', onBeforeunload);
        window.removeEventListener('unload', handleTabClosing);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [],
  );

  React.useEffect(() => {
    setTimeout(() => {
      // chrome extension id, change this in local env for testing
      const extensionId = 'ikgahaiijeoaeeknbphnnjklapmpojpg';
      if (Object.keys(roomStore.info).includes('exam') && roomStore.info.exam.id) {
        try {
          chrome.runtime.sendMessage(extensionId, 'installed&active', (response:string) => {
            if (response !== '👍') {
              incidenceService.create({
                examId: roomStore.info.exam.id,
                type: 'KO_CHROME_EXTENSION',
              });
            }
          });
        } catch (e) {
          incidenceService.create({
            examId: roomStore.info.exam.id,
            type: 'KO_CHROME_EXTENSION',
          });
        }
      }
    }, 500);
  }, [roomStore.info]);

  return (
    <RoundedCard className="w-full h-full">
      {_.isEmpty(roomStore.lmsLink)
        ? <UserPlayer object_fit_contain user={userStore.localUser} className="h-full w-full" focus="PARTICIPANT" hideName />
        : <Exam user={userStore.localUser} link={roomStore.lmsLink} />}
    </RoundedCard>
  );
});

export default ParticipantCall;
