import React, { useState } from "react";
import BackgroundE4Z from "../BackgroundE4Z";

export default function Passcode() {
  const [passcode, setPasscode] = useState("");
  
  const handlePasscode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasscode(event.target.value);
  };
  
  const handleSubmit = (event) => {
    if (event.key === "Enter") {
      // TODO: Validation
      console.log("do validate");
    }
  };

  return (
    <BackgroundE4Z title="Use session passcode">
      <input
        type="text"
        value={passcode}
        onChange={handlePasscode}
        onKeyDown={handleSubmit}
        className="h-10 z-10 rounded-full w-[50%] border-2 appearance-none text-xs border-gray-300 placeholder-gray-300 font-light focus:outline-none focus:bg-white focus:border-gray-500"
      />
    </BackgroundE4Z>
  );
}
