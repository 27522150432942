import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../../../../services/i18n/config';
import { useModalStore, useRoomStore } from '../../../../hooks/store';
import { ExamsUser } from '../../../../types';
import BlueButton from '../../../Button/BlueButton/BlueButton';
import RenderPlayer from '../../../Players/RenderPlayer/RenderPlayer';

type Props = {
  link: string
  user: ExamsUser
}

const Exam: React.FunctionComponent<Props> = observer(({ user, link }: Props) => {
  const { t } = useTranslation();
  const [examOpened, setExamOpened] = useState<boolean>(false);
  const roomStore = useRoomStore();
  const modalStore = useModalStore();

  const handleExamOpened = () => {
    if (examOpened) {
      modalStore.openModal('OPENED_EXAM', {
        onOpen: () => {
          window.open(link, '_blank', 'width=960,height=540');
        },
      });
    } else {
      window.open(link, '_blank', 'width=960,height=540');
      setExamOpened(true);
    }
  };

  return (
    <div className="h-full w-full relative">
      <div className="h-full flex flex-col items-center justify-center text-white space-y-4">
        <p className="font-bold text-white">{t('exam_registration_completed')}</p>
        <p className="text-sm text-white">{t('exam_will_open_new_window')}</p>
        { roomStore.info.exam.password && (
          <p
            className="text-sm text-white"
            //  eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: t('password_credentials', { username: roomStore.info.exam.username, password: roomStore.info.exam.password }) }}
          />
        ) }
        <BlueButton
          disabled={!roomStore.hasStarted}
          onClick={handleExamOpened}
        >
          {t('open_exam')}
        </BlueButton>
      </div>
      <div className="absolute top-0 right-0 shadow-md bg-[#000000] rounded-bl overflow-hidden">
        <RenderPlayer track={user.videoTrack} className="w-20 h-14" />
      </div>
    </div>
  );
});

export default Exam;
