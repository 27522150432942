import axios, { Method } from 'axios';
import {
  action, computed, makeObservable, observable,
} from 'mobx';
import type { UID } from 'agora-rtc-sdk-ng';
import _ from 'lodash/fp';

import i18n from '../../../services/i18n/config';
import { Resetable } from '../../interfaces/resetable';
import ExamsLogger from '../../../logger';
import type { AWSCredentials } from '..';
import RTMStore, { createActionMessage } from '../rtm';
import NotifyStore from '../ui/notify';
import examService from '../../../services/exam.service';
import type { RoomExam } from '../../../types';

import RoomStore from '.';

const logPrefix = '[Record Store]';

export type AcquireParams = {
  channel: string
}

export type StartParams = {
  channel: string
  resourceId: string
  token?: string
  aws?: AWSCredentials
}

export type StopParams = {
  sid: string,
  resourceId: string,
  channel: string
}

const regionMap = {
  'us-east-1': 0,
  'us-east-2': 1,
  'us-west-1': 2,
  'us-west-2': 3,
  'eu-west-1': 4,
  'eu-west-2': 5,
  'eu-west-3': 6,
  'eu-central-1': 7,
  'ap-southeast-1': 8,
  'ap-southeast-2': 9,
  'ap-northeast-1': 10,
  'ap-northeast-2': 11,
  'sa-east-1': 12,
  'ca-central-1': 13,
  'ap-south-1': 14,
  'cn-north-1': 15,
  'cn-northwest-1': 16,
  'us-gov-west-1': 17,
};

// https://docs.agora.io/en/cloud-recording/restfulapi/#/Cloud%20Recording/acquire
const NON_HOST_UID = '4294967295'; // Max unsigned integer

// https://docs.agora.io/en/cloud-recording/restfulapi/
class RecordStore implements Resetable {
  roomStore!: RoomStore

  private static baseURL = 'https://api.agora.io/v1/apps'

  @observable
  recording = false

  @observable
  sidCamera: string = null

  @observable
  sidScreen: string = null

  @observable
  resourceIdCamera: string = null

  @observable
  resourceIdScreen: string = null

  constructor(room: RoomStore) {
    makeObservable(this);
    this.roomStore = room;
  }

  get rtm(): RTMStore {
    return this.roomStore.rtm;
  }

  get notify(): NotifyStore {
    return this.roomStore.appStore.uiStore.notify;
  }

  @computed
  get appId(): string {
    return this.roomStore.appStore.appId;
  }

  @computed
  get awsCredentials(): AWSCredentials {
    return this.roomStore.appStore.awsCredentials;
  }

  @computed
  get authorization(): string {
    return `Basic ${this.roomStore.appStore.restfullAPIToken}`;
  }

  @computed
  get recordingUrl(): string {
    return `${RecordStore.baseURL}/${this.appId}/cloud_recording`;
  }

  @computed
  get isRecording(): boolean {
    return this.recording;
  }

  @computed
  get channelCamera(): string {
    return this.roomStore.rtc.cameraStore.client.channelName;
  }

  @computed
  get channelScreen(): string {
    return this.roomStore.rtc.screenStore.client.channelName;
  }

  @computed
  get token(): string {
    return this.roomStore.rtc.callOptions.token;
  }

  // If we use an UID already in the channel, this UID wont be recorded
  @computed
  get uid(): UID {
    return _.getOr(NON_HOST_UID, 'host.uid', this.roomStore.userStore);
  }

  @computed
  get exam(): RoomExam {
    return this.roomStore.info.exam;
  }

  @action
  reset(): void {
    this.sidCamera = null;
    this.sidScreen = null;
    this.resourceIdCamera = null;
    this.resourceIdScreen = null;
    this.recording = false;
  }

  @action
  setSids(cam: string, screen: string): void {
    this.setSidCam(cam);
    this.setSidScreen(screen);
  }

  @action
  setSidCam(val: string): void {
    this.sidCamera = val;
  }

  @action
  setSidScreen(val: string): void {
    this.sidScreen = val;
  }

  @action
  setResourceIds(cam: string, screen: string): void {
    this.setResourceIdCam(cam);
    this.setResourceIdScreen(screen);
  }

  @action
  setResourceIdCam(val: string): void {
    this.resourceIdCamera = val;
  }

  @action
  setResourceIdScreen(val: string): void {
    this.resourceIdScreen = val;
  }

  @action
  setRecording(val: boolean): void {
    this.recording = val;
  }

  @action
  notifyStartRecording(): void {
    this.notify.showRecordNotification(i18n.t('recording_started'));
    this.setRecording(true);
  }

  @action
  notifyStopRecording(): void {
    this.notify.showRecordNotification(i18n.t('recording_stopped'));
    this.setRecording(false);
  }

  @action
  async autoStartRecording(): Promise<void> {
    if (!this.exam.isUnattended || !this.exam.recordEnabled) return;

    if (await examService.isRecording(this.exam.id)) return;

    await this.startRecording();
  }

  @action
  async startRecording(): Promise<void> {
    if (!this.exam.recordEnabled) return;

    const { id } = this.exam;
    if (await examService.isRecording(id)) return;

    ExamsLogger.info(logPrefix, 'Starting the recording');
    try {
      await examService.startRecording(id);
      const [resource_cam, resource_screen] = await Promise.all([
        this.acquire({ channel: this.channelCamera }),
        this.acquire({ channel: this.channelScreen }),
      ]);
      this.setResourceIds(resource_cam, resource_screen);
      const [sid_cam, sid_screen] = await Promise.all([
        this.start({
          channel: this.channelCamera,
          resourceId: resource_cam,
          token: this.token,
          aws: this.awsCredentials,
        }),
        this.start({
          channel: this.channelScreen,
          resourceId: resource_screen,
          token: this.token,
          aws: this.awsCredentials,
        }),
      ]);
      this.setSids(sid_cam, sid_screen);
      this.setRecording(true);
      this.sendStartNotification();
    } catch (err) {
      this.setSids(null, null);
      this.setResourceIds(null, null);
      this.setRecording(false);
      this.sendStopNotification();
      await examService.stopRecording(id);
      ExamsLogger.error(logPrefix, err);
      throw err;
    }
  }

  @action
  async stopRecording(): Promise<void> {
    if (!this.exam.recordEnabled) return;

    const { id } = this.exam;
    if (!await examService.isRecording(id)) return;

    ExamsLogger.info(logPrefix, 'Stoping the recording');

    try {
      await examService.stopRecording(id);
      await Promise.all([
        this.stop({
          sid: this.sidCamera,
          resourceId: this.resourceIdCamera,
          channel: this.channelCamera,
        }),
        this.stop({
          sid: this.sidScreen,
          resourceId: this.resourceIdScreen,
          channel: this.channelScreen,
        }),
      ]);
      this.setSids(null, null);
      this.setResourceIds(null, null);
      this.setRecording(false);
      this.sendStopNotification();
    } catch (err) {
      ExamsLogger.error(logPrefix, err);
      throw err;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async makeRequest<T>(method: Method, url: string, data?: T): Promise<any> {
    const response = await axios.request({
      method,
      url: `${this.recordingUrl}${url}`,
      headers: {
        Authorization: this.authorization,
      },
      data,
    });
    return response.data;
  }

  @action
  async acquire(config: AcquireParams): Promise<string> {
    const data = {
      cname: config.channel,
      uid: this.uid,
      clientRequest: {
        resourceExpiredHour: 24,
        scene: 2,
      },
    };
    const response = await this.makeRequest('POST', '/acquire', data);
    return response.resourceId;
  }

  @action
  async start(config: StartParams): Promise<string> {
    let data = {
      uid: this.uid,
      cname: config.channel,
      clientRequest: {
        appsCollection: {
          combinationPolicy: 'postpone_transcoding',
        },
        recordingConfig: {
          channelType: 0,
          subscribeUidGroup: 3,
        },
        storageConfig: {
          accessKey: config.aws?.accessKey,
          region: _.get(config.aws?.region, regionMap),
          bucket: config.aws?.bucket,
          secretKey: config.aws?.secretKey,
          vendor: 1, // AWS S3
          fileNamePrefix: [
            config.channel,
            'recordings',
          ],
        },
      },
    };
    if (!_.isEmpty(config.token)) {
      data = _.set('clientRequest.token', config.token, data);
    }
    const response = await this.makeRequest('POST', `/resourceid/${config.resourceId}/mode/individual/start`, data);
    return response.sid;
  }

  @action
  async stop(config: StopParams): Promise<void> {
    const data = {
      cname: config.channel,
      uid: this.uid,
      clientRequest: {},
    };
    await this.makeRequest('POST', `/resourceid/${config.resourceId}/sid/${config.sid}/mode/individual/stop`, data);
  }

  @action
  async sendStartNotification(): Promise<void> {
    const message = createActionMessage({ type: 'START_RECORD' });
    await this.rtm.channel?.sendMessage(message);
  }

  @action
  async sendStopNotification(): Promise<void> {
    const message = createActionMessage({ type: 'STOP_RECORD' });
    await this.rtm.channel?.sendMessage(message);
  }
}

export default RecordStore;
