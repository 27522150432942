import React from 'react';
import _ from 'lodash/fp';

import ParticipantSideBarBeforeExam from '../SideBar/ParticipantSideBar/ParticipantSideBarBeforeExam/ParticipantSideBarBeforeExam';
import CheckAudioAndVideo from '../CheckAudioAndVideo/CheckAudioAndVideo';
import BottomBarBeforeExam, { Props as BottomBarProps } from '../BottomBar/BottomBarBeforeExam/BottomBarBeforeExam';
import { RoomExam } from '../../types';
import { useModalStore } from '../../hooks/store';
import TopBar from '../TopBar/TopBar';

export type Props = BottomBarProps & {
  exam: RoomExam
  role: string,
  demoRole: string,
  share_screen_tutorial_img: string,
}

const ExamConditions: React.FunctionComponent<Props> = ({
  nextURL, exam, role, demoRole, share_screen_tutorial_img,
}: Props) => {
  const modalStore = useModalStore();

  const [extensionInstalled, setExtensionInstalled] = React.useState<boolean>(false);
  const [retryExtensionInstalled, setRetryExtensionInstalled] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const site = _.last(_.split('/', nextURL));
  const examRole = exam.isDemo ? demoRole : role;
  
  React.useEffect(() => {
    setTimeout(() => {
      // chrome extension id, change this in local env for testing
      const extensionId = 'ikgahaiijeoaeeknbphnnjklapmpojpg';
      try {
        if (!extensionInstalled && examRole === 'PARTICIPANT') {
          chrome.runtime.sendMessage(extensionId, 'installed&active', (response:string) => {
            if (response === '👍') {
              setExtensionInstalled(true);
            } else {
              setRetryExtensionInstalled(!retryExtensionInstalled);
            }
            setLoading(false);
          });
        }
      } catch (e) {
        setExtensionInstalled(false);
        setLoading(false);
        setRetryExtensionInstalled(!retryExtensionInstalled);
      }
    }, 500);
  }, [extensionInstalled, retryExtensionInstalled, examRole]);

  const showScreenTutorial = () => {
    if (_.isEqual(examRole, 'PARTICIPANT') && site === 'start') {
      modalStore.openModal('SHARE_SCREEN_TUTORIAL', {
        onOpen: async () => {
          window.location.href = nextURL;
        },
        image: share_screen_tutorial_img,
        registrationId: false,
      });
    } else {
      window.location.href = nextURL;
    }
  };
  const showChromeModal = () => {
    modalStore.openModal('CHROME_EXTENSION', {
      onOpen: async () => {
        // resolving as a promise  wait for a modal been closed before open a new one
        const closeModal = new Promise((resolve) => {
          modalStore.closeModal('CHROME_EXTENSION');
          resolve(modalStore);
        });
        await closeModal;
        showScreenTutorial();
      },
    });
  };

  return (
    <div className="h-screen w-full bg-black flex flex-col">
      <TopBar hideSpeakingToAndDropdown />
      <div className="flex flex-row flex-1">
        { _.isEqual(examRole, 'PARTICIPANT') && <ParticipantSideBarBeforeExam registrationEnabled={exam.identityRegistration} /> }
        <div className="p-2 pt-0 w-full h-full">
          <CheckAudioAndVideo
            loading={loading}
            extensionInstalled={extensionInstalled}
          />
        </div>
      </div>
      <BottomBarBeforeExam
        nextURL={nextURL}
        callback={!loading && !extensionInstalled && examRole === 'PARTICIPANT' ? showChromeModal : showScreenTutorial}
        forceCallback
      />
    </div>
  );
};

export default ExamConditions;
