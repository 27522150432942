import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useOutletContext } from "react-router-dom";
import { ExamCredentials } from "../../../factories";

import "../../../services/i18n/config";
import { ContextRoom } from "../../../types";
import { ExamsUserRole } from "../../../types/enum";
import AppWrapper from "../../AppWrapper/AppWrapper";
import ExamConditionsComponent from "../../ExamConditions/ExamConditions";
import PreroomWrapper from "../../Room/Preroom";

export default function Requirements() {
  const { t } = useTranslation();
  const { exam, user } = useOutletContext<ContextRoom>();
  const userIsParticipant = user.roles.includes(ExamsUserRole.PARTICIPANT);
  const { search } = useLocation();
  return (
    <AppWrapper
      credentials={ExamCredentials}
    >
      <PreroomWrapper
        exam={exam}
        user={user}
        route={t("requirements")}
      >
        <ExamConditionsComponent
          nextURL={`/exams/${exam.id}/${exam.identityRegistration && userIsParticipant ? 'registration' : 'start'}${search}`}
          exam={exam}
          role={user.roles[0]}
          demoRole={user.roles[0]}
          share_screen_tutorial_img={`${process.env.PUBLIC_URL}/share_screen_tutorial.png`}
        />
      </PreroomWrapper>
    </AppWrapper>
  );
}
