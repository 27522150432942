import React, { useState, useEffect } from 'react';

import { useRoomStore } from '../../../../../hooks/store';
import client from '../../../../../services/client';
import { ExamsUser } from '../../../../../types';
import IdentityBlock from '../../../../IdentityValidation/IdentityBlock';
import UserPlayer from '../../../../Players/UserPlayer/UserPlayer';
import RoundedCard from '../../../../RoundedCard/RoundedCard';

import styles from './RenderUser.module.scss';

type RenderUserProps = {
  participant: ExamsUser;
  numberOfParticipants: number;
  handleDoubleClick: (participant: ExamsUser) => void;
};

type DocCheck = {
  extracted: Record<string, unknown>;
  documentDataChecking: Array<{ ocr: string, category: string }>;
  status: string;
};

type IdentityDataInterface = {
  docCheck: DocCheck;
  docImg: string;
  faceMatchingImg: string;
  overallResult?: {
    status: 'declined' | 'approved',
    comments: Array<{
      service: 'liveness' | 'doc-check' | 'face-matching',
      status: 'approved' | 'declined',
      comment: string
    }>,
  }
};

const RenderUser: React.FunctionComponent<RenderUserProps> = (props: RenderUserProps) => {
  const { participant, handleDoubleClick, numberOfParticipants } = props;

  const [identityData, setIdentityData] = useState<IdentityDataInterface>({
    docCheck: { extracted: {}, documentDataChecking: [], status: 'denied' },
    docImg: '',
    faceMatchingImg: '',
  });
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [examHasIdValidation, setExamHasIdValidation] = useState(false);
  const roomStore = useRoomStore();

  useEffect(() => {
    client
      .post('/api/registration/exam_has_validation', {
        exam: roomStore.info.exam.id,
      })
      .then((response) => {
        setExamHasIdValidation(response.data.exam_has_validation);
      });
  }, [roomStore.info.exam.id]);

  useEffect(() => {
    if (examHasIdValidation) {
      client
        .post('/api/registration/result_by_exam', {
          username: participant.username,
          exam: roomStore.info.exam.id,
        })
        .then((response) => {
          const { data } = response;
          if (data.success) {
            setIdentityData({
              docCheck: data.docCheck,
              docImg: data.docImg,
              faceMatchingImg: data.faceMatchingImg,
              overallResult: data.overallResult,
            });
            setSuccess(true);
          }
          setLoading(false);
        });
    }
  }, [roomStore.info.exam.id, participant, examHasIdValidation]);
  return (
    <div
      onDoubleClick={() => handleDoubleClick(participant)}
      key={participant.uid}
    >
      <RoundedCard className={styles.roundedCard}>
        <UserPlayer
          object_fit_contain
          focus="PARTICIPANT"
          className={styles.h70}
          user={participant}
        />
        {!success ? null : (
          <div className={numberOfParticipants > 1 ? styles.imgBlockBig : styles.imgBlockSmall}>
            <img src={identityData.faceMatchingImg} alt="img" />
            <img src={identityData.docImg} alt="img" />
          </div>
        )}
        {loading ? null : (
          <IdentityBlock
            docCheck={identityData.docCheck}
            username={participant.username}
            overallResult={identityData.overallResult}
          />
        )}
      </RoundedCard>
    </div>
  );
};

export default RenderUser;
