import _ from 'lodash/fp';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useUserStore, useViewModeStore } from '../../../../hooks/store';
import HybridPlayer from '../../../Players/HybridPlayer/HybridPlayer';
import RoundedCard from '../../../RoundedCard/RoundedCard';
import LeftButton from '../Button/LeftButton/LeftButton';
import RightButton from '../Button/RightButton/RightButton';

const HybridExamView: React.FunctionComponent = observer(() => {
  const viewModeStore = useViewModeStore();
  const userStore = useUserStore();

  const users = userStore.hybridExamModeParticipants;
  const maxUsers = viewModeStore.getNumUsers('HYBRID_EXAM');
  const enoughUsers = _.size(userStore.participants) > maxUsers;

  if (_.isEmpty(users)) {
    return null;
  }

  return (
    <div
      className="grid gap-2 h-full w-full relative"
      style={{
        gridTemplateColumns: users.length < 5 ? 'repeat(auto-fit, minmax(32rem, 1fr))' : 'repeat(auto-fit, minmax(24rem, 1fr))',
        gridTemplateRows: 'repeat(auto-fit, minmax(18rem, 1fr)',
      }}
    >
      {enoughUsers && <LeftButton onClick={() => viewModeStore.prev('HYBRID_EXAM')} />}
      {users.map((participant) => (
        <div
          onDoubleClick={() => userStore.doubleClickOnParticipant(participant)}
          key={participant.uid}
        >
          <RoundedCard className="h-full">
            <HybridPlayer
              focus="EXAM"
              className="h-full w-full"
              user={participant}
            />
          </RoundedCard>
        </div>
      ))}
      {enoughUsers && <RightButton onClick={() => viewModeStore.next('HYBRID_EXAM')} />}
    </div>
  );
});

export default HybridExamView;
