import { ID } from '../types';

import client from './client';

type CreateParams = {
  comment: string,
  user: string,
  examId: ID
}
// TODO: change url to new backend
export const create = async (data: CreateParams): Promise<void> => {
  const formData = new FormData();
  formData.append('comment[comment]', data.comment);
  formData.append('comment[user]', data.user.toString());

  const response = await client.post(
    `/exams/${data.examId}/comments`,
    formData,
  );
  return response.data;
};

const CommentService = {
  create,
};

export default CommentService;