import React from "react";
import BackgroundE4Z from "../BackgroundE4Z";

type Props = {
  message?: string;
};

export default function Error({ message }: Props) {
  return (
    <BackgroundE4Z title={message || "404 Page not found"}>
      <a
        className="z-10 w-50 inline-flex items-center justify-center px-3 py-2 border border-transparent text-lg font-bold rounded-lg shadow-sm text-white bg-[#2DABFF] hover:bg-blue-300 focus:outline-none mt-16"
        href={process.env.REACT_APP_BASE_URL}
      >
        Back to dashboard
      </a>
    </BackgroundE4Z>
  );
}
