import React from "react";
import { useOutletContext } from "react-router-dom";
import { ExamCredentials } from "../../../factories";
import Icons from "../../../images/icons";
import { ContextRoom } from "../../../types";
import BottomBarBeforeExam from "../../BottomBar/BottomBarBeforeExam/BottomBarBeforeExam";
import Wrapped from "../../SideBar/ParticipantSideBar/ParticipantSideBarBeforeExam/Wrapped/Wrapped";
import ParticipantTopBar from "../../TopBar/ParticipantTopBar/ParticipantTopBar";

// TODO: trasnlation & form
export default function Finish() {
  const { exam, user } = useOutletContext<ContextRoom>();
  return (
    <>
      <div className="bg-black flex flex-col h-screen">
        <ParticipantTopBar
          examName={exam.name}
          duration={exam.duration}
          takeScreenshot={exam.takeScreenshot}
          uploadFiles={exam.uploadFiles}
          recordEnabled={exam.recordEnabled}
          isUnattended={exam.isUnattended}
        />
        <div className="flex flex-row">
          <Wrapped
            credentials={ExamCredentials}
            exam={exam}
            user={user}
            route="Finish"
          />
          <div className="flex flex-col flex-1 bg-white border border-black border-l-8">
            <div className="flex h-screen justify-center items-center">
              <div className="w-1/2 text-center p-20">
                <strong className="text-lg">
                  Exam session has ended, please upload your final results
                </strong>
              </div>
            </div>
          </div>
          <BottomBarBeforeExam nextURL="" />
        </div>
      </div>
      <div className="fixed z-10 inset-0 overflow-hidden visible bg-opacity-0" aria-labelledby="modal-title" role="dialog"
    aria-modal="true" id="feedback-form-modal">
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" aria-hidden="true"></div>

        <div className="flex justify-center mx-auto transition-all duration-500 ease-out" id="feedback-form-modal">
            <div className="absolute inset-0 flex items-center justify-center bg-gray-700 bg-dark-transparent">
                <div className="px-12 bg-[#FCFCFD] text-[#23262F] text-center relative">
                  <Icons.Close className="absolute right-7 top-7 cursor-pointer w-3" />
                    <div className="p-6">
                        <div className="items-center justify-between mt-4 relative">
                            <div className="flex justify-center">
                                <h1 className="font-bold font-size-25 w-55">Exam session has ended, please upload your final results</h1>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </div>
    </>
  );
}
