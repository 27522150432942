import React from 'react';

import ArrowButton from '../../ArrowButton/ArrowButton';

type Props = {
  collapsed: boolean
  onClick: (collapsed: boolean) => void
}

const CollapsableButton: React.FunctionComponent<Props> = ({ collapsed, onClick }: Props) => {
  const direction = collapsed ? 'right' : 'left';

  return (
    <ArrowButton onClick={() => onClick(collapsed)} stickDirection="left" arrowDirection={direction} />
  );
};

export default CollapsableButton;
