import { ID } from '../types';
import { ExamIncidence } from '../channels/exam_incidences_channel';

import client from './client';

type ExamResponse = {
  id: number
  finishedParticipantsCount: number
  value: string | number | number[]
}
// TODO: Change url to new backend
export const get = async (id: ID): Promise<ExamResponse> => {
  const response = await client.get(`/api/exams/${id}`);
  return response.data;
};

export const finish = async (id: ID): Promise<void> => {
  await client.post(`/api/exams/${id}/finish`);
};

export const start = async (id: ID): Promise<void> => {
  await client.post(`/api/exams/${id}/start`);
};

export const stop = async (id: ID): Promise<void> => {
  await client.post(`/api/exams/${id}/stop`);
};

export const getEndTime = async (id: ID): Promise<string> => {
  const response = await client.get(`/api/exams/${id}/end_time`);
  return response.data;
};

export const startRecording = async (id: ID): Promise<void> => {
  await client.post(`/api/exams/${id}/start_recording`);
};

export const stopRecording = async (id: ID): Promise<void> => {
  await client.post(`/api/exams/${id}/stop_recording`);
};

export const isRecording = async (id: ID): Promise<boolean> => {
  const response = await client.get(`/api/exams/${id}/is_recording`);
  return response.data;
};

export const saveAnalysis = async (id: ID, type:string, value:any): Promise<void> => {
  await client.put(`/api/exams/${id}/save_analysis`, { type, value });
};

export const getUserIncidences = async (id: ID): Promise<ExamIncidence[]> => {
  const response = await client.get(`/api/exams/${id}/user_incidences`);
  return response.data;
};

export const abandonDemo = async (id: ID): Promise<void> => {
  await client.post(`/api/exams/${id}/abandon_demo`);
};

const ExamService = {
  get,
  finish,
  start,
  stop,
  getEndTime,
  startRecording,
  stopRecording,
  isRecording,
  saveAnalysis,
  getUserIncidences,
  abandonDemo,
};

export default ExamService;
