import { UID } from 'agora-rtc-sdk-ng';
import { action, makeObservable } from 'mobx';

import type { ActionMessage } from '../rtm';
import ExamsLogger from '../../../logger';

import RecordStore from './record';
import UserStore from './user';
import HandStore from './hand';

import RoomStore from '.';

const logPrefix = '[Action Store]';
class ActionStore {
  roomStore!: RoomStore

  constructor(room: RoomStore) {
    makeObservable(this);
    this.roomStore = room;
  }

  get userStore(): UserStore {
    return this.roomStore.userStore;
  }

  get handStore(): HandStore {
    return this.roomStore.handStore;
  }

  get recordStore(): RecordStore {
    return this.roomStore.recordStore;
  }

  @action
  async executeActionMessage(actionMessage: ActionMessage): Promise<void> {
    ExamsLogger.info(logPrefix, 'New action received:', actionMessage);
    switch (actionMessage.type) {
      case 'ENABLE_HOST_AUDIO':
        this.userStore.listenToHost();
        break;
      case 'DISABLE_HOST_AUDIO':
        this.userStore.notListenToHost();
        break;
      case 'RAISE_HAND':
        this.handStore.notifyRaiseHand(actionMessage.params.uid as UID);
        break;
      case 'LOWER_HAND':
        this.handStore.lowerHand(actionMessage.params.uid as UID);
        break;
      case 'START_RECORD':
        this.recordStore.notifyStartRecording();
        break;
      case 'STOP_RECORD':
        this.recordStore.notifyStopRecording();
        break;
      case 'START_EXAM':
        this.roomStore.notifyStartExam();
        break;
      case 'END_EXAM':
        this.roomStore.notifyEndExam();
        window.location.reload();
        break;
      case 'CURRENT_ROUTE':
        this.userStore.setUserRoute(
          actionMessage.params.uid as UID,
          actionMessage.params.route as string,
        );
        break;
      default:
        break;
    }
  }
}

export default ActionStore;
