import React from "react";
import { Route, Routes } from "react-router-dom";
import "./stylesheets/application.scss";
import Welcome from "./components/Pages/Welcome/Welcome";
import Instructions from "./components/Pages/Instructions/Instructions";
import Lounge from "./components/Pages/Lounge/Lounge";
import Requirements from "./components/Pages/Requirements/Requirements";
import Start from "./components/Pages/Start/Start";
import Feedback from "./components/Pages/Feedback/Feedback";
import Finish from "./components/Pages/Finish/Finish";
import Passcode from "./components/Pages/Passcode/Passcode";
import Error from "./components/Pages/Error/Error";
import Protected from "./components/Pages/Protected/Protected";
import ExamAuthorization from "./components/ExamAuthorization/ExamAuthorization";
import Registration from "./components/Pages/Registration/Registration";

function App(): JSX.Element {
  return (
    <Routes>
      <Route
        path="/unauthorized"
        element={<Error message={"Authorization failed"} />}
      />
      <Route path="/" element={<Protected />}>
        <Route path="/" element={<ExamAuthorization />} />
        <Route path="exams/:id/lounge" element={<Lounge />} />
        <Route path="exams/:id/welcome" element={<Welcome />} />
        <Route path="exams/:id/passcode" element={<Passcode />} />
        <Route path="exams/:id/instructions" element={<Instructions />} />
        <Route path="exams/:id/requirements" element={<Requirements />} />
        <Route path="exams/:id/registration" element={<Registration />} />
        <Route path="exams/:id/start" element={<Start />} />
        <Route path="exams/:id/finish" element={<Finish />} />
        <Route path="exams/:id/feedback" element={<Feedback />} />
      </Route>
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default App;
