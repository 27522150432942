import React from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import '../../../services/i18n/config';
import Icons from '../../../images/icons';
import { ExamsUser } from '../../../types';
import UserAvatar from '../../UserAvatar/UserAvatar';
import events from '../../../lib/events';

type Props = {
  participant: ExamsUser
  odd: boolean
  handRaise?: {
    isRaised: boolean,
  },
  incidentTriggered?:{
    color: string,
  }
  messages?: {
    hasUnread: boolean
    onClick: (user: ExamsUser) => void
  },
  onDoubleClick: (user: ExamsUser) => void,
  hasAbandoned?: boolean,
}

const Participant: React.FunctionComponent<Props> = ({
  participant,
  odd,
  messages,
  handRaise,
  incidentTriggered,
  onDoubleClick,
  hasAbandoned,
}: Props) => {
  const { t } = useTranslation();
  const isGreen = (route: string) => _.includes(route, [t('exam_finished'), t('feedback_submitted')]);
  const onClick = () => messages.onClick(participant);
  const color = incidentTriggered?.color ? incidentTriggered?.color : 'bg-[#C9CAC8]';
  const activityColor = isGreen(participant.route) ? 'bg-[#3CD23D]' : color;

  return (
    <div
      className={`flex flex-row items-center space-x-2 h-16 px-3 ${odd ? 'bg-gray-100' : ''}`}
      onDoubleClick={events.preventParentTrigger(() => onDoubleClick(participant))}
    >
      <div className={`rounded h-6 w-2 ${activityColor}`} />
      <UserAvatar user={participant} />
      <div className="flex flex-col flex-1 overflow-hidden">
        <span className="text-sm font-medium truncate">{participant.username}</span>
        <span className="text-2xs font-light truncate">
          {' '}
          {hasAbandoned ? t('abandoned_exam') : participant.route}
        </span>
      </div>
      <div className="flex flex-row space-x-2 text-black">
        {messages?.hasUnread && <Icons.Chat className="h-4 cursor-pointer" onClick={events.preventParentTrigger(onClick)} />}
        {handRaise?.isRaised && <Icons.Hand className="h-4" />}
        {incidentTriggered?.color && <Icons.Alert className="h-4" />}
      </div>
    </div>
  );
};

export default Participant;
