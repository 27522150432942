import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../../services/i18n/config';
import { ModalEndOpenedExam } from '../../../types';
import BlueButton from '../../Button/BlueButton/BlueButton';
import RedButton from '../../Button/RedButton/RedButton';
import Base, { BaseModalProps } from '../Base/Base';

type Props = BaseModalProps & {
  data: ModalEndOpenedExam
}

const OpenedExam: React.FunctionComponent<Props> = ({ data, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Base onClose={onClose}>
      <div className="flex flex-col space-y-10 mx-8">
        <div className="font-bold text-xl">
          {t('exam_already_opened')}
        </div>
        <div className="flex items-center justify-center space-x-2">
          <BlueButton onClick={() => {
            onClose();
            data.onOpen();
          }}
          >
            {t('open_exam')}
          </BlueButton>
          <RedButton onClick={() => {
            onClose();
          }}
          >
            {t('cancel')}
          </RedButton>
        </div>
      </div>
    </Base>
  );
};

export default OpenedExam;
