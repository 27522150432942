import React from 'react';

type Props = {
  name: string
}

const UserNamePlayer: React.FunctionComponent<Props> = ({ name }: Props) => (
  <div
    className="absolute left-0 bottom-0 py-2 px-3 max-w-full backdrop-filter backdrop-blur-lg bg-[#000000] bg-opacity-40"
  >
    <span className="block text-white truncate text-xs">
      {name}
    </span>
  </div>
);

export default UserNamePlayer;
