import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useCollapsedStore } from '../../../hooks/store';
import CollapsableButton from '../CollapsableButton/CollapsableButton';

type Props = {
  children: React.ReactNode;
};
const CollapsableSideBar: React.FunctionComponent = observer(({ children }: Props) => {
  const collapsedStore = useCollapsedStore();

  const isCollapsed = collapsedStore.isCollapsed('SIDEBAR');

  const sidebarClass = classNames(
    'transition-all ease-in-out duration-300 flex flex-col h-full overflow-hidden',
    {
      'w-64': !isCollapsed,
      'w-0': isCollapsed,
    },
  );

  return (
    <div className="relative h-full">
      <div className={sidebarClass}>
        {children}
      </div>
      <div className="absolute flex flex-col h-full items-center justify-center left-[100%] top-0">
        <CollapsableButton
          collapsed={isCollapsed}
          onClick={(collapsed) => collapsedStore.collapse('SIDEBAR', !collapsed)}
        />
      </div>
    </div>
  );
});

export default CollapsableSideBar;
