import React from 'react';

import Icons from '../../../../images/icons';
import RoundedCard from '../../../RoundedCard/RoundedCard';

import styles from './PlusAndMinus.module.scss';

type Props = {
  disabledMinus?: boolean
  disabledPlus?: boolean
  onClickMinus: () => void
  onClickPlus: () => void
}

const PlusAndMinus: React.FunctionComponent<Props> = ({
  onClickMinus,
  onClickPlus,
  disabledMinus = false,
  disabledPlus = false,
}: Props) => (
  <RoundedCard className="h-full">
    <div className={styles.wrapper}>
      <button type="button" className={styles.button} disabled={disabledMinus} onClick={onClickMinus}>
        <Icons.Minus width="12px" />
      </button>
      <button type="button" className={styles.button} disabled={disabledPlus} onClick={onClickPlus}>
        <Icons.Plus width="12px" />
      </button>
    </div>
  </RoundedCard>
);

export default PlusAndMinus;
