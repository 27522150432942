import { ReactComponent as Camera } from '../assets/images/icon_camera.svg';
import { ReactComponent as Chevron } from '../assets/images/icon_chevron.svg';
import { ReactComponent as Hand } from '../assets/images/icon_hand.svg';
import { ReactComponent as Microphone } from '../assets/images/icon_micro.svg';
import { ReactComponent as MicOff } from '../assets/images/icon_micoff.svg';
import { ReactComponent as Audio } from '../assets/images/icon_audio.svg';
import { ReactComponent as Chat } from '../assets/images/icon_chat.svg';
import { ReactComponent as Users } from '../assets/images/icon_users.svg';
import { ReactComponent as User } from '../assets/images/icon_user.svg';
import { ReactComponent as Record } from '../assets/images/icon_record.svg';
import { ReactComponent as Security } from '../assets/images/icon_security.svg';
import { ReactComponent as SecurityBlue } from '../assets/images/icon_security_blue.svg';
import { ReactComponent as Emoji } from '../assets/images/icon_emoji.svg';
import { ReactComponent as Snapshot } from '../assets/images/icon_snapshot.svg';
import { ReactComponent as SoundWave } from '../assets/images/icon_sound_wave.svg';
import { ReactComponent as Close } from '../assets/images/icon_close.svg';
import { ReactComponent as Logo } from '../assets/images/logo_exam.svg';
import { ReactComponent as Minus } from '../assets/images/icon_minus.svg';
import { ReactComponent as Plus } from '../assets/images/icon_plus.svg';
import { ReactComponent as Alert } from '../assets/images/icon_alert.svg';
import { ReactComponent as Play } from '../assets/images/icon_play.svg';
import { ReactComponent as Pause } from '../assets/images/icon_pause.svg';
import { ReactComponent as FileUpload } from '../assets/images/icon_file_upload.svg';
import { ReactComponent as IdBlue } from '../assets/images/icon_id_blue.svg';
import { ReactComponent as Minimize } from '../assets/images/icon_minimize_blue.svg';
import { ReactComponent as Reset } from '../assets/images/icon_reset.svg';
import { ReactComponent as Grid } from '../assets/images/icon_grid.svg';
import { ReactComponent as Focus } from '../assets/images/icon_focus.svg';
import { ReactComponent as Bell } from '../assets/images/icon_bell.svg';
import { ReactComponent as BellAlt } from '../assets/images/icon_bell_alt.svg';
import { ReactComponent as Duration } from '../assets/images/icon_duration.svg';
import { ReactComponent as Comment } from '../assets/images/icon_comment_2.svg';
import { ReactComponent as CommentAlt } from '../assets/images/icon_comment.svg';
import { ReactComponent as Envelop } from '../assets/images/icon_envelop.svg';
import { ReactComponent as FileBlank } from '../assets/images/icon_file_blank.svg';
import { ReactComponent as Exclamation } from '../assets/images/icon_exclamation.svg';
import { ReactComponent as Bookmark } from '../assets/images/icon_bookmark.svg';
import { ReactComponent as BackgroundE4Z } from '../assets/images/background_e4z.svg';
import { ReactComponent as Logo2 } from '../assets/images/icon_logo_2.svg';
import { ReactComponent as Globe } from '../assets/images/icon_globe.svg';

const Icons = {
  Camera,
  Chevron,
  Hand,
  Microphone,
  MicOff,
  Audio,
  Chat,
  User,
  Users,
  Record,
  Security,
  SecurityBlue,
  Emoji,
  Snapshot,
  SoundWave,
  Close,
  Logo,
  Minus,
  Plus,
  Alert,
  Play,
  Pause,
  FileUpload,
  IdBlue,
  Minimize,
  Reset,
  Grid,
  Focus,
  Bell,
  BellAlt,
  Duration,
  Comment,
  CommentAlt,
  Envelop,
  FileBlank,
  Exclamation,
  Bookmark,
  BackgroundE4Z,
  Logo2,
  Globe,
};


export default Icons;