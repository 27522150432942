import React from 'react';

import styles from './Flash.module.scss';

export type FlashProps = {
  show: boolean;
  message: string;
  id?: string;
};

type Props = React.PropsWithChildren<FlashProps>;

const Flash: React.FunctionComponent<Props> = ({
  show,
  message,
  id,
}: Props) => (
  React.createElement('div', {
    className: `flash notice absolute ${styles.flash_message}`,
    'x-data': `{isOpen: ${show && message !== ''} }`,
    'x-show.transition.duration.700ms.opacity': 'isOpen',
    id: id || '',
  }, message)
);

export default Flash;
