import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

import Icons from '../../../images/icons';

export type BaseModalProps = {
  onClose: () => void,
  className?: string;
  disableCloseButton?: boolean;
}

type Props = React.PropsWithChildren<BaseModalProps>

const Base: React.FunctionComponent<Props> = ({
  onClose, className = '', disableCloseButton, children,
}: Props) => (
  <Transition appear show as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={onClose}
    >
      <div className="min-h-screen px-4 text-center flex items-center justify-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className={`transition-all transform shadow-xl border-[2px] px-[10%] py-6 bg-white ${className}`}>
            {
              !disableCloseButton && (
              <div className="flex flex-row justify-end p-2">
                <button type="button" onClick={onClose} className="focus:outline-none absolute right-5 top-5">
                  <Icons.Close className="h-4" />
                </button>
              </div>
              )
            }
            <div>
              {children}
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
);

export default Base;
