import React from "react";

import ExamComponent from "../../Exam/Exam";
import AppWrapper from "../../AppWrapper/AppWrapper";
import RoomWrapper from "../../Room/Room";
import { ExamCredentials } from "../../../factories";
import { useOutletContext } from "react-router-dom";
import { ContextRoom } from "../../../types";

export default function Start() {
  const { exam, user } = useOutletContext<ContextRoom>();
  
  return (
    <AppWrapper
      credentials={ExamCredentials}
    >
      <RoomWrapper
        exam={exam}
        user={user}
        route="Exam"
      >
        <ExamComponent />
      </RoomWrapper>
    </AppWrapper>
  );
}
