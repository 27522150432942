import { ID } from '../types';

import consumer from './consumer';

export type ExamIncidenceType = 'ABSENT_PARTICIPANT' | 'PARTICIPANT_NOT_ALONE' | 'KO_ID_VALIDATION' | 'DROP_LVL_ATTENTION' | 'NEW_TAB_WINDOW' | 'STOP_SHARING_DESKTOP' | 'USER_DROPPED' | 'CAMERA_STOPPED' | 'MICROPHONE_STOPPED' | 'KO_CHROME_EXTENSION' | 'COPY_EXTERNAL_TEXT' | 'TAB_ACTIVATE' | 'PASTE_TEXT' | 'FILE_DOWNLOADED'

export type ExamIncidence = {
  id: ID,
  exam_id: ID,
  user_id: ID,
  created_at: string
  updated_at: string
  incidence_type: ExamIncidenceType
}

export type Subscription = ReturnType<typeof consumer.subscriptions.create>

const createExamIncidencesChannel = (examId: ID): Subscription => (
  consumer.subscriptions.create(
    {
      channel: 'ExamIncidencesChannel',
      exam_id: examId,
    },
  ));

const ExamIncidencesChannel =  { createExamIncidencesChannel };

export default ExamIncidencesChannel;