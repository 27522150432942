import React from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { ExamCredentials } from "../../../factories";
import Icons from "../../../images/icons";
import { ContextRoom } from "../../../types";
import BottomBarBeforeExam from "../../BottomBar/BottomBarBeforeExam/BottomBarBeforeExam";
import Wrapped from "../../SideBar/ParticipantSideBar/ParticipantSideBarBeforeExam/Wrapped/Wrapped";
import ParticipantTopBar from "../../TopBar/ParticipantTopBar/ParticipantTopBar";

// TODO: Translations & check again in legacy app/views/instructions/index.html.erb
export default function Instructions() {
  const { exam, user } = useOutletContext<ContextRoom>();
  const { search } = useLocation();

  return (
    <div className="bg-black flex flex-col h-screen">
      <ParticipantTopBar
        examName={exam.name}
        duration={exam.duration}
        takeScreenshot={exam.takeScreenshot}
        uploadFiles={exam.uploadFiles}
        recordEnabled={exam.recordEnabled}
        isUnattended={exam.isUnattended}
      />
      <div className="flex flex-row h-full">
        <Wrapped
          credentials={ExamCredentials}
          exam={exam}
          user={user}
          route="Instructions"
        />
        <div className="flex flex-col flex-1 overflow-y-scroll">
          <div className="flex flex-1 items-stretch p-2 pt-0">
            <div className="flex bg-white min-w-full rounded-sm p-8 pb-16">
              <div className="flex flex-col flex-1 w-1/2 text-gray-700">
                <h2 className="text-2xl text-gray-700 mb-6">
                  Exam Session Guidelines
                </h2>
                <p className="text-base mb-4 font-semibold">
                  Before you begin your exam remember!
                </p>
                <div className="flex items-center mb-4">
                  <Icons.Record className="w-7 mr-4 text-blue" />
                  <p className="text-base">
                    Camera and a microphone are required.
                    {true && "You will be recorded."}
                  </p>
                </div>
                {false && (
                  <div className="flex items-center mb-4">
                    <Icons.IdBlue className="w-7 mb-4 text-blue" />
                    <p className="text-base">
                      Some tests require an ID validation. Try to have it at
                      hand
                    </p>
                  </div>
                )}
                <div className="flex items-center mb-4">
                  <Icons.Hand className="w-7 mr-4 text-blue" />
                  <p className="text-base">
                    Need help? Ask questions in the chat box or{" "}
                    <b>raise your hand to your host.</b>
                  </p>
                </div>
                <div className="flex items-center mb-4">
                  <Icons.Minimize className="w-7 mr-4 text-blue" />
                  <p className="text-base">
                    Need to focus? Click on the <b>left sidebar</b> to{" "}
                    <b>minimise it.</b>
                  </p>
                </div>
                <div className="flex items-center mb-4">
                  <p className="text-base">
                    During the exam your host may drop out of the session. If
                    this happens, continue with your exam and do not worry
                  </p>
                </div>
                <div className="flex items-center mt-8 mb-4">
                  <Icons.Alert className="w-7 mr-4 text-blue" />
                  <p className="text-base font-semibold">
                    You and your institution will be automatically notified
                    every time that you:
                  </p>
                </div>

                <ul className="list-disc text-base mb-4 pl-4 italic">
                  <li>
                    <b>Go out</b> of camera view
                  </li>
                  <li>
                    Allow <b>someone else</b> into camera view.
                  </li>
                  <li>
                    Open or <b>copy/paste</b> from a <b>newtab</b> or{" "}
                    <b>window</b>.
                  </li>
                  <li>
                    <b>Stop sharing</b> your <b>desktop.</b>
                  </li>
                  <li>
                    Have an <b>undetectable audio</b> stream or <b>camera.</b>
                  </li>
                  <li>
                    <b>Close</b> the main exam session <b>window</b>
                  </li>
                </ul>
              </div>
              <div className="w-px bg-gray-500 justify-items-center my-8 mx-8"></div>
              <div className="flex flex-col flex-1 w-1/2 custom-instructions">
                <h2 className="text-2xl text-gray-700 mb-6">
                  Academic Integrity
                </h2>
                <div className="text-base mb-4 ">
                  <div>
                    We are trust based. By continuing you agree to uphold to
                    Exam’s standards of academic integrity.
                  </div>
                  <ul>
                    <li>
                      <b>Do your own work.</b> All answers, except for those
                      that allow collaboration should be your own work.
                    </li>
                    <li>
                      <b>No sharing.</b> Your work and answers, including exams
                      and exam questions, should not be shared with anyone else.
                    </li>
                    <li>
                      <b>Try your best.</b> Avoid any activities that may
                      improve your results dishonestly.
                    </li>
                  </ul>
                  <div>
                    <li>
                      <b>
                        In the case that any dishonest activities or plagiarism
                        are found to have occurred, you and your institution
                        will be notified.
                      </b>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomBarBeforeExam nextURL={`/exams/${1}/requirements${search}`} />
      </div>
    </div>
  );
}
