import React from 'react';
import _ from 'lodash/fp';
import { observer } from 'mobx-react-lite';

import { useToastStore } from '../../hooks/store';

export const useTimeout = (fn: CallableFunction, delay: number): void => {
  const timer = React.useRef(null);

  /* eslint-disable  react-hooks/exhaustive-deps */
  React.useEffect(() => {
    timer.current = setTimeout(() => {
      fn?.();
    }, delay);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [timer]);
  /* eslint-enable  react-hooks/exhaustive-deps */
};

type Props = {
  id: string;
  message: string;
  closeToast: (id: string) => void;
};

export const Toast: React.FunctionComponent<Props> = ({ id, message, closeToast }: Props) => {
  useTimeout(() => {
    closeToast(id);
  }, 2500);

  return (
    <div data-test-id={`toast-${id}`} className="flex items-center justify-center">
      <div className="rounded bg-blue shadow px-2 py-1">
        <span className="text-white">{message}</span>
      </div>
    </div>
  );
};

const ToastList = observer(() => {
  const store = useToastStore();

  return (
    <div className="fixed flex flex-col space-y-4 top-0 items-center w-full z-50 pointer-events-none select-none mt-4">
      {_.map(
        (toast) => (
          <Toast
            key={toast.id}
            message={toast.message}
            id={toast.id}
            closeToast={(id) => store.removeToast(id)}
          />
        ),
        store.toastQueue,
      )}
    </div>
  );
});

export default ToastList;
