import _ from 'lodash/fp';
import { observer } from 'mobx-react-lite';
import React from 'react';
import NotificationHandler from 'react-web-notification';

import { useNotifyStore } from '../../hooks/store';
import NotifyStore from '../../stores/app/ui/notify';

const Notification: React.FunctionComponent = observer(() => {
  const notifyStore = useNotifyStore();

  const next = notifyStore.nextNotification;

  React.useEffect(() => {
    NotifyStore.init();
  }, []);

  if (_.isEmpty(next)) return null;

  return (
    <NotificationHandler
      onClose={() => notifyStore.dequeueNotification()}
      timeOut={5000}
      title={next.title}
      options={next.options}
      onClick={() => {
        window.focus();
        next.actions?.onClick?.();
      }}
    />
  );
});

export default Notification;
