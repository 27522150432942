import { ITrack } from 'agora-rtc-sdk-ng';
import _ from 'lodash/fp';
import React from 'react';

type Props = {
  track?: ITrack
  className?: string
}

const RenderPlayer: React.FunctionComponent<Props> = ({ track, className }: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!_.isNil(ref.current)) {
      track?.play(ref.current);
    }
    return () => track?.stop();
  }, [ref, track]);

  return (
    <div className={className} ref={ref} />
  );
};

export default RenderPlayer;
