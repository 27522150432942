import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../services/i18n/config';
import Icons from '../../images/icons';

import styles from './IdentityBlock.module.scss';

type DocCheck = {
  extracted: Record<string, unknown>;
  documentDataChecking: Array<{ ocr: string, category: string}>;
  status: string;
};

type OverallResult = {
  status: 'declined' | 'approved',
  comments: Array<{
    service: 'liveness' | 'doc-check' | 'face-matching',
    status: 'approved' | 'declined',
    comment: string
  }>,
}

type Props = {
  docCheck: DocCheck;
  overallResult: OverallResult;
  username: string;
  type?: 'primary' | 'secondary';
};

type Data = {
  email: string;
  name: string;
  country: string;
  documentType: string;
  expirationDate: string;
  birth: string;
}
const IdentityBlock: React.FunctionComponent<Props> = ({
  docCheck, overallResult, username, type = 'primary',
}: Props) => {
  const { t } = useTranslation();
  const { documentDataChecking } = docCheck;
  const formatDocumentType = (document:string):string => (document
    ? document.replace('-', ' ').charAt(0).toUpperCase() + document.slice(1)
    : 'Unknown');
  const capitalizeString = (string:string):string => string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  const getFullName = ():string => {
    if (documentDataChecking.length > 0) {
      const firstName = documentDataChecking.find((data) => data.category === 'First name')?.ocr || '';
      const lastName = documentDataChecking.find((data) => data.category === 'Last name')?.ocr || '';
      return capitalizeString(`${firstName} ${lastName}`.toLowerCase());
    }

    return '';
  };
  const data:Data = {
    email: username,
    name: getFullName(),
    documentType: formatDocumentType(docCheck.documentDataChecking?.find((docData) => docData.category === 'Document type')?.ocr) || '',
    expirationDate: docCheck.documentDataChecking?.find((docData) => docData.category === 'Date of expiry')?.ocr || '',
    birth: docCheck.documentDataChecking?.find((docData) => docData.category === 'Date of birth')?.ocr || '',
    country: docCheck.documentDataChecking?.find((docData) => docData.category === 'Issue country')?.ocr || '',
  };
  return (
    <div className={`${type} ${type === 'primary' ? styles.block : styles.block_secondary}`}>
      <div className={styles.title}>
        { overallResult && (
          <Icons.Security className={overallResult.status === 'approved' ? 'h-4 mr-1 text-green' : 'h-4 mr-1 text-red'} />
        ) }
        <h3>
          {t('identity_validation')}
        </h3>
      </div>
      <div className="text-sm text-red mb-3">
        { overallResult && overallResult.status === 'declined' && (
          overallResult.comments.map((comment, idx) => {
            if (comment.status === 'declined') {
              return (
              // eslint-disable-next-line react/no-array-index-key
                <p key={idx}>{comment.comment}</p>
              );
            }
            // eslint-disable-next-line react/no-array-index-key
            return <div key={idx} />;
          })
        )}
      </div>
      <div className={styles.content}>
        <div className={`${styles.data} space-x-2`}>
          <Icons.User className="w-[20px] h-[20px]" />
          <div>
            <h5>{t('name')}</h5>
            <p>{data.name}</p>
          </div>
        </div>
        <div className={`${styles.data} space-x-2`}>
          <Icons.Envelop className="w-[20px] h-[16px] text-blue" />
          <div>
            <h5>{t('email')}</h5>
            <p>{data.email}</p>
          </div>
        </div>
        <div className={`${styles.data} space-x-2`}>
          <Icons.Globe className="text-blue w-[20px] h-[20px]" />
          <h5>{t('country')}</h5>
          <p>{data.country}</p>
        </div>
        <div className={`${styles.data} space-x-2`}>
          <Icons.FileBlank className="text-blue w-[16px] h-[20px]" />
          <div>
            <h5>{t('document_type')}</h5>
            <p>{data.documentType}</p>
          </div>
        </div>
        <div className={`${styles.data} space-x-2`}>
          <Icons.Exclamation className="text-blue w-[16px] h-[20px]" />
          <div>
            <h5>{t('expiration_date')}</h5>
            <p>{data.expirationDate}</p>
          </div>
        </div>
        <div className={`${styles.data} space-x-2`}>
          <Icons.Bookmark className="text-blue w-[16px] h-[20px]" />
          <div>
            <h5>{t('birth_date')}</h5>
            <p>{data.birth}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdentityBlock;
