/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../../services/i18n/config';
import Icons from '../../../images/icons';
import Tooltip from '../Tooltip';
import styles from '../Tooltip.module.scss';

type Props = React.PropsWithChildren<{
  offset?: string
  event?: string
  eventOff?: string
  tooltipId?: string
  align?: string
  duration?: number,
  takeScreenshot?: boolean,
  uploadFiles?: boolean,
  recordEnabled?: boolean,
  isUnattended?: boolean,
  isDemo?: boolean,
}>

const ParticipantToolTip: React.FunctionComponent<Props> = ({
  offset, event, eventOff, align, tooltipId, children,
  duration = 0, takeScreenshot = false,
  uploadFiles = false, recordEnabled = false, isUnattended = false, isDemo = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      place="left"
      content={(
        <div className={align}>
          <div className="flex justify-between">
            <div>
              <h4 className={styles.tooltipTitle}>
                {t('during_your_exam_please_remember')}
              </h4>
              <div className="flex items-center mb-4">
                <div className="w-5 mr-4 text-[#2DABFF]">
                  <Icons.Record />
                </div>
                <p className={styles.text}>
                  {`${!recordEnabled ? t('youll_be_recorded') : ''}`}
                  {t('you_need_a_camera_microphone')}
                </p>
              </div>
              <div className="flex items-center mb-4">
                <div className="w-5 mr-4 text-[#2DABFF]">
                  <Icons.Hand />
                </div>
                <p className={styles.text}>
                  {`${!isUnattended ? t('need_help_and_raise_your_hand') : t('need_help')}`}
                </p>
              </div>
              <div className="flex items-center mb-4">
                <div className="w-5 mr-4 text-[#2DABFF]">
                  <Icons.Minimize />
                </div>
                <p className={styles.text}>
                  {t('need_focus')}
                </p>
              </div>
              <div className="flex items-center mb-4">
                <p className={styles.text}>
                  {t('anything_else')}
                  <a href="https://examsforzoom.com/faqs/" target="_blank" className="text-[#2DABFF]" rel="noreferrer"> <u>{t('here')}!</u></a>
                </p>
              </div>
            </div>
            <div className="w-px bg-gray-500 justify-items-center my-2 mx-6" />
            <div>
              <h4 className={styles.tooltipTitle}>{t('technical_details')}</h4>
              <ul>
                <li className="mb-2">
                  {`· ${t('exam_duration')} : ${!isDemo ? duration : '∞'} ${t('minutes')}`}
                </li>
                <li className="mb-2">
                  · {t('recorded')}:
                  {' '}
                  <span className={recordEnabled ? 'text-[#2DABFF] font-bold' : 'text-[#D0021B] font-bold'}>
                    {`${recordEnabled ? t('yes') : t('no')}`}
                  </span>
                </li>
                <li className="mb-2">
                  · {t('additional_actions')}
                  {' '}
                  {`${takeScreenshot ? t('snapshot') : ''}`}
                  {`${takeScreenshot && uploadFiles ? '/' : ''}`}
                  {`${uploadFiles ? t('upload_files') : ''}`}
                </li>
              </ul>
            </div>
          </div>
        </div>
        )}
      offset={offset}
      event={event}
      eventOff={eventOff}
      tooltipId={tooltipId}
      className={styles.participantTooltip}
    >
      {children}
    </Tooltip>
  );
};

export default ParticipantToolTip;
