import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useOutletContext } from 'react-router-dom';
import { ExamCredentials } from '../../../factories';

import '../../../services/i18n/config';
import { ContextRoom } from '../../../types';
import AppWrapper from '../../AppWrapper/AppWrapper';
import RegistrationComponent from '../../Registration/Registration';
import PreroomWrapper from '../../Room/Preroom';

const Registration: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { exam, user } = useOutletContext<ContextRoom>();
  const { search } = useLocation();
  return (
    <AppWrapper credentials={ExamCredentials}>
      <PreroomWrapper exam={exam} user={user} route={t('registration_id_validation')}>
        <RegistrationComponent
          nextURL={`/exams/${exam.id}/start${search}`}
          exam={exam}
          share_screen_tutorial_img={`${process.env.PUBLIC_URL}/share_screen_tutorial.png`}
        />
      </PreroomWrapper>
    </AppWrapper>
  );
};

export default Registration;
