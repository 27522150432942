import {
  action, computed, makeObservable, observable,
} from 'mobx';
import _ from 'lodash/fp';

import i18n from '../../../services/i18n/config';
import AppStore from '..';
import { Resetable } from '../../interfaces/resetable';
import type { ExamsUser, RoomExam, RoomUser } from '../../../types';
import RTCStore from '../rtc';
import RTMStore, { createActionMessage } from '../rtm';
import type { TabsName } from '../../../components/SideBar/Tabs/Tabs';
import NotifyStore from '../ui/notify';
import examService from '../../../services/exam.service';

import ChatStore from './chat';
import UserStore from './user';
import RecordStore from './record';
import ActionStore from './action';
import HandStore from './hand';
import SentimentStore from './sentiment';
import IncidenceStore from './incidence';
import { ExamsUserRole } from '../../../types/enum';

type RoomInfo = {
  user: RoomUser
  exam: RoomExam
}

class RoomStore implements Resetable {
  appStore!: AppStore;

  @observable
  info: RoomInfo = null;

  @observable
  activeTab: TabsName = 'participants'

  chatStore: ChatStore

  userStore: UserStore

  recordStore: RecordStore

  handStore: HandStore

  sentimentStore: SentimentStore

  incidenceStore: IncidenceStore

  actionStore: ActionStore

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
    this.chatStore = new ChatStore(this);
    this.userStore = new UserStore(this);
    this.recordStore = new RecordStore(this);
    this.actionStore = new ActionStore(this);
    this.handStore = new HandStore(this);
    this.sentimentStore = new SentimentStore(this);
    this.incidenceStore = new IncidenceStore(this);
  }

  @action
  reset(): void {
    this.info = null;
    this.activeTab = 'participants';

    this.userStore.reset();
    this.chatStore.reset();
    this.recordStore.reset();
    this.handStore.reset();
    this.sentimentStore.reset();
    this.incidenceStore.reset();
    this.activeTab = 'participants';
  }

  @action
  setHasStarted(hasStarted: boolean): void {
    this.info.exam.hasStarted = hasStarted;
  }

  @action
  setActiveTab(tab: TabsName): void {
    this.activeTab = tab;
  }

  get notify(): NotifyStore {
    return this.appStore.uiStore.notify;
  }

  get rtc(): RTCStore {
    return this.appStore.rtcStore;
  }

  get rtm(): RTMStore {
    return this.appStore.rtmStore;
  }

  @computed
  get examName(): string {
    return _.get('info.exam.name', this);
  }

  @computed
  get hasStarted(): boolean {
    return _.get('info.exam.hasStarted', this);
  }

  @computed
  get isRecordingEnabled(): boolean {
    return _.get('info.exam.recordEnabled', this);
  }

  @computed
  get isSnapshotEnabled(): boolean {
    return _.get('info.exam.takeScreenshot', this);
  }

  @computed
  get isDemo(): boolean {
    return _.get('info.exam.isDemo', this);
  }

  @computed
  get endTime(): Date | null {
    if (_.isEmpty(this.info?.exam?.endTime)) return null;

    return new Date(this.info.exam.endTime);
  }

  @computed
  get lmsLink(): string {
    return _.get('exam.lmsURL', this.info);
  }

  @computed
  get localUser(): ExamsUser {
    return this.userStore.localUser;
  }

  @computed
  get otherUsers(): ExamsUser[] {
    return this.userStore.otherUsers;
  }

  @computed
  get participants(): ExamsUser[] {
    return this.userStore.participants;
  }

  @computed
  get host(): ExamsUser {
    return this.userStore.host;
  }

  @action
  async join(roomInfo: RoomInfo, route: string): Promise<void> {
    try {
      this.appStore.uiStore.loading.startLoading();
      this.info = roomInfo;

      const uid = `${this.info.user.id}`;
      
      let role;
      if (this.info.exam.isDemo) {
        role = this.info.user.demoRole;
      } else {
        role = this.info.user.roles.includes(ExamsUserRole.PARTICIPANT) ? ExamsUserRole.PARTICIPANT : ExamsUserRole.HOST
      }

      const options = {
        uid,
        token: null,
        channel: _.toString(this.info.exam.agoraId),
        user: {
          uid,
          role,
          username: this.info.user.name,
          route,
        },
      };
      await this.rtm.startMessaging(options);

      await this.rtc.startCall(options);

      this.incidenceStore.init();

      await this.sendLocation(route);

      if (!this.userStore.isLocalHost()) {
        // TODO: activate sentiment analysis
        // this.sentimentStore.startAnalysis();
      }

      await this.autoStart();

      this.appStore.uiStore.loading.stopLoading();
    } catch (err) {
      this.appStore.uiStore.loading.stopLoading();
      throw err;
    }
  }

  @action
  async joinPre(roomInfo: RoomInfo, route: string): Promise<void> {
    this.info = roomInfo;
    
    const uid = `${this.info.user.id}`;

    let role;
    if (this.info.exam.isDemo) {
      role = this.info.user.demoRole;
    } else {
      role = this.info.user.roles.includes(ExamsUserRole.PARTICIPANT) ? ExamsUserRole.PARTICIPANT : ExamsUserRole.HOST
    }

    const options = {
      uid,
      token: null,
      channel: _.toString(this.info.exam.agoraId),
      user: {
        uid,
        role,
        username: this.info.user.name,
        route,
      },
    };
    await this.rtm.startMessaging(options);

    await this.rtc.cameraStore.startCall(options);

    await this.sendLocation(route);
  }

  async sendLocation(route: string): Promise<void> {
    if (this.userStore.isLocalHost()) return;

    if (_.isEmpty(this.host)) return;

    const message = createActionMessage({ type: 'CURRENT_ROUTE', params: { uid: this.localUser.uid, route } });
    this.rtm.client?.sendMessageToPeer(message, _.toString(this.host.uid));
  }

  async autoStart(): Promise<void> {
    if (!this.info.exam.isUnattended) return;

    await this.autoStartExam();
    await this.recordStore.autoStartRecording();
  }

  @action
  async autoStartExam(): Promise<void> {
    if (!this.info.exam.isUnattended) return;

    if (!_.isEmpty(await examService.getEndTime(this.info.exam.id))) return;

    await this.startExam();
  }

  @action
  setEndTime(val: string): void {
    this.info.exam.endTime = val;
  }

  @action
  async controlAnalysis(evt: string): Promise<void>  {
    await this.sentimentStore.pausePlayAnalysis(evt);
  }

  @action
  async leave(): Promise<void> {
    await this.rtc.leaveCall();
    this.rtm.leaveMessaging();
    await this.sentimentStore.stopAnalysis();
  }

  @action
  async startExam(): Promise<void> {
    // await examService.start(this.info.exam.id);

    const message = createActionMessage({ type: 'START_EXAM' });
    await this.rtm.channel?.sendMessage(message);

    this.notifyStartExam();
  }

  @action
  async endExam(): Promise<void> {
    // await examService.stop(this.info.exam.id);

    const message = createActionMessage({ type: 'END_EXAM' });
    this.rtm.channel?.sendMessage(message);
  }

  @action
  async notifyStartExam(): Promise<void> {
    const endTime = "2022-09-23T09:01:00.000Z";
    this.setEndTime(endTime);
    const message = this.info.exam.recordEnabled ? i18n.t('exam_has_started_recording') : i18n.t('exam_has_started');
    this.notify.showExamNotification(message);
  }

  @action
  notifyEndExam(): void {
    this.notify.showExamNotification(i18n.t('exam_ended'));
  }

  @action
  async fiveMinutesLeft(): Promise<void> {
    const message = createActionMessage({ type: 'FIVE_MINUTES_LEFT' });
    await this.rtm.channel?.sendMessage(message);

    this.notify.showExamNotification(i18n.t('five_minutes_left'));
  }
}

export default RoomStore;
