import type { UID } from 'agora-rtc-sdk-ng';
import _ from 'lodash/fp';
import {
  action, computed, makeObservable, observable,
} from 'mobx';

import { Resetable } from '../../interfaces/resetable';
import Channel, {
  ExamIncidence,
  ExamIncidenceType,
  Subscription,
} from '../../../channels/exam_incidences_channel';
import type { ID, RoomExam } from '../../../types';
import NotifyStore from '../ui/notify';

import UserStore from './user';

import RoomStore from '.';

export type Incidence = {
  id: ID
  uid: UID
  type: ExamIncidenceType
  timestamp: number
  read: boolean
}

export type ParticipantsPerIncidence = {
  [key: string]: Array<number>;
};

class IncidenceStore implements Resetable {
  roomStore!: RoomStore;

  @observable
  incidences: Incidence[] = [];

  @observable
  selected: UID | null = null

  channel: Subscription = null;

  constructor(room: RoomStore) {
    makeObservable(this);
    this.roomStore = room;
  }

  get userStore(): UserStore {
    return this.roomStore.userStore;
  }

  @computed
  get sortedIncidences(): Incidence[] {
    return _.sortBy('timestamp', this.incidences);
  }

  @computed
  get uniqueIncidencesTriggered(): Incidence[] {
    // eslint-disable-next-line arrow-body-style
    return this.incidences.filter((i1, index) => {
      return this.incidences.findIndex((i2) => i1.type === i2.type) === index;
    });
  }

  @computed
  get participantsPerIncidence(): ParticipantsPerIncidence {
    const counts = {};
    this.incidences.forEach((incidence) => {
      if (counts[incidence.type] === undefined) {
        counts[incidence.type] = [];
      }
      if (!counts[incidence.type].includes(incidence.uid)) {
        counts[incidence.type].push(incidence.uid);
      }
    });
    return counts;
  }

  @computed
  get exam(): RoomExam {
    return this.roomStore.info.exam;
  }

  @computed
  get totalIncidences(): number {
    return this.incidences.length;
  }

  shouldNotifyCurrentParticipant(incidences: ExamIncidence[]): boolean {
    return _.size(incidences) === 1
      && NotifyStore.shouldNotifyParticipant(incidences[0])
      && this.roomStore.userStore.isLocalUser(incidences[0].user_id);
  }

  isIncidentTriggered(uid: UID): string {
    let color = '';
    this.incidences.forEach((incidence) => {
      if (incidence.uid === uid && incidence.read === false) {
        color = incidence.type === 'DROP_LVL_ATTENTION' ? 'bg-[#F2A20C]' : 'bg-[#FE5900]';
      }
    });
    return color;
  }

  userIncidences(uid: UID): Incidence[] {
    const userIncidences: Incidence[] = [];
    _.forEach((incidence) => {
      if (incidence.uid === uid) {
        userIncidences.push(incidence);
      }
    }, this.incidences);
    return _.sortBy('timestamp', userIncidences);
  }

  userDropped(uid: UID): boolean {
    let dropped = false;

    this.incidences.forEach((incidence) => {
      if (incidence.uid === uid) {
        const { participants } = this.userStore;
        const userInChannel = _.find((user) => user.uid === uid && user.screenTrack, participants);
        dropped = incidence.type === 'USER_DROPPED' && !userInChannel;
      }
    });
    return dropped;
  }

  @action
  setSelected(uid: UID | null): void {
    this.selected = uid;
  }

  @action
  markRead(): void {
    const incidenceRead: Incidence[] = [];
    _.forEach((incidence) => {
      const incident = { ...incidence };
      incident.read = true;
      incidenceRead.push(incident);
    }, this.incidences);
    this.setIncidences(incidenceRead);
  }

  @action
  init(): void {
    this.channel = Channel.createExamIncidencesChannel(this.exam.id);
    this.channel.received = (data: ExamIncidence[]) => {
      if (this.shouldNotifyCurrentParticipant(data)) {
        this.roomStore.notify.showParticipantNotification(data[0]);
      }

      if (!this.userStore.isLocalHost()) return;

      const newIncidences: Incidence[] = _.map(
        (incidence) => ({
          id: incidence.id,
          uid: incidence.user_id,
          type: incidence.incidence_type,
          timestamp: new Date(incidence.created_at).getTime(),
          read: false,
        }),
        data,
      );
      this.setIncidences(_.concat(this.incidences, newIncidences));
    };
  }

  @action
  setIncidences(incidences: Incidence[]): void {
    this.incidences = incidences;
  }

  @action
  reset(): void {
    this.incidences = [];
    this.channel = null;
    this.selected = null;
  }
}

export default IncidenceStore;
