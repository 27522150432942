import _ from 'lodash/fp';
import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../../../services/i18n/config';
import CollapsableSideBar from '../../CollapsableSideBar/CollapsableSideBar';

import Item from './Item/Item';
import HostCam from './HostCam/HostCam';

type Props = {
  registrationEnabled: boolean;
}

const ParticipantSideBarBeforeExam: React.FunctionComponent<Props> = (
  {
    registrationEnabled,
  }: Props,
) => {
  const { t } = useTranslation();

  const menuListWithRegistration = [
    { name: t('instructions'), url: 'instructions' },
    { name: t('requirements'), url: 'requirements' },
    { name: t('registration'), url: 'registration' },
    { name: _.capitalize(t('exam')), url: 'start' },
    { name: t('finish'), url: 'finish' },
  ];

  const menuListWithoutRegistration = [
    { name: t('instructions'), url: 'instructions' },
    { name: t('requirements'), url: 'requirements' },
    { name: _.capitalize(t('exam')), url: 'start' },
    { name: t('finish'), url: 'finish' },
  ];

  const menuList = registrationEnabled ? menuListWithRegistration : menuListWithoutRegistration;

  const isActive = (url: string): boolean => _.contains(url, window.location.href);

  const isCompleted = (url: string): boolean => {
    const activeIndex = _.findIndex((item) => isActive(item.url), menuList);
    const currentIndex = _.findIndex({ url }, menuList);
    return currentIndex < activeIndex;
  };

  return (
    // BUG: Type '{ children: Element[]; }' has no properties in common with type 'IntrinsicAttributes'.ts
    // @ts-ignore
    <CollapsableSideBar>
      <HostCam />
      <div className="bg-white flex-1">
        {menuList.map((item, index) => (
          <Item
            key={item.name}
            text={item.name}
            odd={(index + 1) % 2 !== 0}
            isActive={isActive(item.url)}
            isCompleted={isCompleted(item.url)}
          />
        ))}
      </div>
    </CollapsableSideBar>
  );
};

export default ParticipantSideBarBeforeExam;
