import AgoraRTC from 'agora-rtc-sdk-ng';
import {
  action, makeObservable, observable,
} from 'mobx';
import _ from 'lodash/fp';

import AppStore from '..';
import { Resetable } from '../../interfaces/resetable';
import RTCStore from '../rtc';

class DeviceStore implements Resetable {
  appStore!: AppStore

  @observable
  cameraList: MediaDeviceInfo[] = []

  @observable
  microphoneList: MediaDeviceInfo[] = []

  constructor(app: AppStore) {
    makeObservable(this);
    this.appStore = app;
  }

  get rtc(): RTCStore {
    return this.appStore.rtcStore;
  }

  @action
  async init(): Promise<void> {
    const cameras = await AgoraRTC.getCameras();
    const microphones = await AgoraRTC.getMicrophones();
    this.setCameras(cameras);
    this.setMicrophones(microphones);
  }

  @action
  setCameras(cameras: MediaDeviceInfo[]): void {
    this.cameraList = cameras;
  }

  @action
  setMicrophones(microphones: MediaDeviceInfo[]): void {
    this.microphoneList = microphones;
  }

  @action
  reset(): void {
    this.cameraList = [];
    this.microphoneList = [];
  }

  @action
  async changeMicrophone(id: string): Promise<void> {
    if (_.isNil(this.rtc.localAudioTrack)) return;

    await this.rtc.localAudioTrack.setDevice(id);
    sessionStorage.microphoneId = id;
  }

  @action
  async changeCamera(id: string): Promise<void> {
    if (_.isNil(this.rtc.localVideoTrack)) return;

    await this.rtc.localVideoTrack.setDevice(id);
    sessionStorage.cameraId = id;
  }
}

export default DeviceStore;
