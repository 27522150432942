import _ from 'lodash/fp';
import React from 'react';

type Props = {
  name: string
}

const UserLetters: React.FunctionComponent<Props> = ({ name }: Props) => (
  <div className="rounded overflow-hidden h-6 w-6">
    <div className="w-full h-full flex items-center justify-center uppercase text-white font-bold bg-blue">
      {_.join('', _.take(2, name))}
    </div>
  </div>
);

export default UserLetters;
