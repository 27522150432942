import _ from 'lodash/fp';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useUserStore, useViewModeStore } from '../../../../hooks/store';
import LeftButton from '../Button/LeftButton/LeftButton';
import RightButton from '../Button/RightButton/RightButton';

import RenderUser from './RenderUser/RenderUser';

const IdentityView: React.FunctionComponent = observer(() => {
  const viewModeStore = useViewModeStore();
  const userStore = useUserStore();

  const users = userStore.participantModeParticipants;
  const maxUsers = viewModeStore.getNumUsers('IDENTITY');
  const enoughUsers = _.size(userStore.participants) > maxUsers;

  if (_.isEmpty(users)) {
    return null;
  }

  return (
    <div
      className="grid gap-2 h-full w-full relative"
      style={{
        gridTemplateColumns: 'repeat(auto-fit, minmax(16rem, 1fr)',
        gridTemplateRows: 'repeat(auto-fit, minmax(12rem, 1fr)',
      }}
    >
      {enoughUsers && (
        <LeftButton onClick={() => viewModeStore.prev('IDENTITY')} />
      )}
      {users.map((participant) => (
        <RenderUser
          key={participant.uid}
          participant={participant}
          numberOfParticipants={users.length}
          handleDoubleClick={() => userStore.doubleClickOnParticipant(participant)}
        />
      ))}
      {enoughUsers && (
        <RightButton onClick={() => viewModeStore.next('IDENTITY')} />
      )}
    </div>
  );
});

export default IdentityView;
