import React from 'react';

import AppWrapper, { AppProps } from '../../../../AppWrapper/AppWrapper';
import PreroomWrapper, { RoomProps } from '../../../../Room/Preroom';
import ParticipantSideBarBeforeExam from '../ParticipantSideBarBeforeExam';

type Props = AppProps & RoomProps

const Wrapped: React.FunctionComponent<Props> = ({
  credentials, exam, user, route,
}: Props) => (
  <AppWrapper credentials={credentials}>
    <PreroomWrapper exam={exam} user={user} route={route}>
      <ParticipantSideBarBeforeExam registrationEnabled={exam.identityRegistration} />
    </PreroomWrapper>
  </AppWrapper>
);

export default Wrapped;
