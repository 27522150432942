import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../../services/i18n/config';
import { useUserStore, useToastStore, useViewModeStore } from '../../../hooks/store';
import { ExamsUser } from '../../../types';
import Popover from '../../Popover/Popover';
import Icons from '../../../images/icons';
import styles from '../BottomBar.module.scss';

const SpeakTo: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const toastStore = useToastStore();
  const viewModeStore = useViewModeStore();

  const disabled = viewModeStore.isMode('ONE_ON_ONE');

  if (!userStore.isLocalHost()) {
    return null;
  }

  const onSpeakToParticipant = async (participant: ExamsUser) => {
    userStore.listenToOneParticipant(participant.uid);
    await userStore.speakToOneParticipant(participant.uid);
    toastStore.addToast(`${t('speaking_to')} ${participant.username}`);
  };

  const onSpeakToAll = async () => {
    userStore.listenToAllParticipants();
    await userStore.speakToAllParticipants();
    toastStore.addToast(t('speaking_to_all'));
  };

  const onSpeakToNone = async () => {
    userStore.listentToNoneParticipants();
    await userStore.speakToNoneParticipants();
    toastStore.addToast(t('speaking_to_none'));
  };

  return (
    <Popover
      disabled={disabled}
      content={(
        <div className={`${styles.popover}`}>
          <span className={`${styles.popoverOption}`} onClick={onSpeakToAll}>
            {t('speak_to_all')}
          </span>
          <Popover
            position="right-end"
            content={
            userStore.totalParticipants > 0
              ? (
                <div className={`${styles.popover} max-h-60 overflow-auto`}>
                  {userStore.participants.map((participant) => (
                    <span
                      key={participant.uid}
                      className={`${styles.popoverOption} `}
                      onClick={() => onSpeakToParticipant(participant)}
                    >
                      {participant.username}
                    </span>
                  ))}
                </div>
              )
              : null
          }
          >
            <span className={`${styles.popoverOption}`}>
              {t('speak_to')}
              ...
            </span>
          </Popover>
          <span className={`${styles.popoverOption}`} onClick={onSpeakToNone}>
            {t('none')}
          </span>
        </div>
    )}
    >
      <div className={`${styles.popoverButton}`}>
        <div className="relative">
          <Icons.Microphone className="h-8" />
          <Icons.Chevron
            className="absolute w-2 top-0 -right-4 transform rotate-180"
            width="10px"
          />
        </div>
        <span className="text-xs text-center leading-4">
          {t('speak_to')}
        </span>
      </div>
    </Popover>
  );
});

export default SpeakTo;
