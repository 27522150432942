import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../../services/i18n/config';
import events from '../../../lib/events';
import { ModalCommentData } from '../../../types';
import BlueButton from '../../Button/BlueButton/BlueButton';
import Base, { BaseModalProps } from '../Base/Base';

type Props = BaseModalProps & {
  data: ModalCommentData
}

const Comment: React.FunctionComponent<Props> = ({ data, onClose }: Props) => {
  const { t } = useTranslation();
  const [comment, setComment] = React.useState('');
  return (
    <Base onClose={onClose} className="py-12">
      <div data-test-id="comment-modal-content" className="flex flex-col space-y-4 w-full">
        <div className="flex flex-col items-center space-y-4">
          <span className="text-xl font-bold text-center">{t('comment')}</span>
        </div>
        <div className="flex flex-col items-center space-y-4 w-full">
          <textarea
            value={comment}
            onChange={events.handleStringChange(setComment)}
            placeholder={t('type_here')}
            className="w-full bg-transparent focus:outline-none rounded-md min-h-[200px] min-w-[500px] placeholder-white resize-none"
          />
        </div>
        <div className="flex flex-row items-center justify-end">
          <BlueButton
            onClick={() => {
              data.onSubmit(comment);
              onClose();
            }}
            disabled={comment === ''}
          >
            {t('submit')}
          </BlueButton>
        </div>
      </div>
    </Base>
  );
};

export default Comment;
