import React from "react";
import { Navigate, useLocation, useOutletContext } from "react-router-dom";
import { ContextRoom } from "../../types";

export default function ExamAuthorization() {
  const { exam, user } = useOutletContext<ContextRoom>();
  const { search } = useLocation();

  if (!exam) {
    return <Navigate to="/unauthorization" />;
  }

  return <Navigate to={`/exams/${exam.id}/welcome${search}`} />
}
