import React from 'react';

import { AppProvider } from '../../context';
import AppStore, { ExamsCredentials } from '../../stores/app';
import LoadContainer from '../Loading/Loading';
import ToastList from '../Toast/Toast';
import ModalContainer from '../Modals/Modals';
import Notification from '../Notification/Notification';

export type AppProps = {
  credentials: ExamsCredentials
}

type Props = React.PropsWithChildren<AppProps>

const AppWrapper: React.FunctionComponent<Props> = ({ children, credentials }: Props) => {
  const defaultStore = React.useRef(
    new AppStore(credentials),
  );

  return (
    <AppProvider store={defaultStore.current}>
      <LoadContainer />
      <ToastList />
      <Notification />
      <ModalContainer />
      {children}
    </AppProvider>
  );
};

export default AppWrapper;
