import React from 'react';
import { Popover as HeadlessPopover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import ReactDOM from 'react-dom';

type Props = React.PropsWithChildren<{
  content: React.ReactNode,
  position?: Placement,
  portal?: boolean
  disabled?: boolean
}>

const Popover: React.FunctionComponent<Props> = ({
  children, content, position, portal = false, disabled = false,
}: Props) => {
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: position ?? undefined,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 20],
        },
      },
    ],
  });

  const panel = () => (
    <HeadlessPopover.Panel
      ref={setPopperElement}
      style={styles.popper}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes.popper}
      className="z-50"
    >
      {content}
    </HeadlessPopover.Panel>
  );

  return (
    <HeadlessPopover className="relative">
      <>
        <HeadlessPopover.Button
          disabled={disabled}
          ref={setReferenceElement}
          className={`focus:outline-none h-full w-full text-left ${disabled ? 'cursor-not-allowed' : ''}`}
        >
          {children}
        </HeadlessPopover.Button>
        {portal
          ? ReactDOM.createPortal(
            panel(),
            document.querySelector('body'),
          )
          : panel()}
      </>
    </HeadlessPopover>
  );
};

export default Popover;
