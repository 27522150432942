import React from 'react';

import Button, { Props } from '../Button';

const BlueButton: React.FunctionComponent<Props> = ({
  children, className = '', disabled = false, onClick,
}: Props) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    className={`bg-blue text-white ${className}`}
  >
    {children}
  </Button>
);

export default BlueButton;
