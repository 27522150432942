import { observer } from 'mobx-react-lite';
import React from 'react';

import { useRoomStore, useUserStore } from '../../hooks/store';

import HostTopBar from './HostTopBar/HostTopBar';
import ParticipantTopBar from './ParticipantTopBar/ParticipantTopBar';

type Props = {
  hideSpeakingToAndDropdown?: boolean,
}

const TopBar: React.FunctionComponent<Props> = observer(({ hideSpeakingToAndDropdown }: Props) => {
  const userStore = useUserStore();
  const roomStore = useRoomStore();

  return (
    <div className="h-10 w-full flex items-center">
      {userStore.isLocalHost()
        ? (
          <HostTopBar
            examName={roomStore.examName}
            hideSpeakingToAndDropdown={hideSpeakingToAndDropdown}
            numberOfParticipants={roomStore.participants.length}
            duration={roomStore.info.exam?.duration}
            idValidation={roomStore.info.exam?.identityRegistration}
            uploadFiles={roomStore.info.exam?.uploadFiles}
          />
        )
        : (
          <ParticipantTopBar
            examName={roomStore.examName}
            duration={roomStore.info && roomStore.info.exam?.duration}
            takeScreenshot={roomStore.info && roomStore.info.exam?.takeScreenshot}
            uploadFiles={roomStore.info && roomStore.info.exam?.uploadFiles}
            recordEnabled={roomStore.info && roomStore.info.exam?.recording}
            isUnattended={roomStore.info && roomStore.info.exam?.unattended}
            showHostBusyLabel
          />
        )}
    </div>
  );
});

export default TopBar;
