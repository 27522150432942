import _ from 'lodash/fp';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../../services/i18n/config';
import { useRoomStore, useViewModeStore } from '../../../hooks/store';
import { Views } from '../../../types';
import Dropdown from '../../Dropdown/Dropdown';

import PlusAndMinus from './PlusAndMinus/PlusAndMinus';

const ViewModeDropdown: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const viewModeStore = useViewModeStore();
  const roomStore = useRoomStore();

  const currentMode = viewModeStore.mode;

  const options = [
    { value: 'PARTICIPANT', label: t('participant') },
    { value: 'EXAM', label: _.capitalize(t('exam')) },
    { value: 'HYBRID_EXAM', label: t('hybrid_exam') },
    { value: 'HYBRID_PARTICIPANT', label: t('hybrid_participant') },
    { value: 'ONE_ON_ONE', label: t('one_on_one') },
  ];
  if (roomStore.info.exam.identityRegistration) {
    options.splice(3, 0, { value: 'IDENTITY', label: t('identity') });
  }

  const selectedMode = _.find({ value: currentMode }, options);

  return (
    <div className="flex flex-row items-center justify-center space-x-1">
      <Dropdown
        mode="DARK"
        options={options}
        className="w-[176px]"
        value={selectedMode}
        onChange={(option) => viewModeStore.changeMode(option.value as keyof Views)}
      />
      <PlusAndMinus
        disabledMinus={viewModeStore.isRangeAtMinimum(currentMode)}
        disabledPlus={viewModeStore.isRangeAtMaximum(currentMode)}
        onClickMinus={() => viewModeStore.decreaseUsers(currentMode)}
        onClickPlus={() => viewModeStore.increaseUsers(currentMode)}
      />
    </div>
  );
});

export default ViewModeDropdown;
