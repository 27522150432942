import { observer } from 'mobx-react-lite';
import React from 'react';

import { useUserStore } from '../../hooks/store';

import HostSideBar from './HostSideBar/HostSideBar';
import ParticipantSideBar from './ParticipantSideBar/ParticipantSideBar';

const SideBar: React.FunctionComponent = observer(() => {
  const userStore = useUserStore();

  return (
    <div className="bg-white">
      {userStore.isLocalHost() ? <HostSideBar /> : <ParticipantSideBar />}
    </div>
  );
});

export default SideBar;
