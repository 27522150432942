import React from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { ContextRoom } from "../../../types";
import CountDown from "../../CountDown/CountDown";
import BackgroundE4Z from "../BackgroundE4Z";

export default function Lounge() {
  const { exam, user } = useOutletContext<ContextRoom>();
  const { search } = useLocation();
  return (
    <BackgroundE4Z title={<>Your <span className="text-[#00A0DF]">exam</span> will start in</>}>
      <div className="text-white">
        <CountDown dateTime={exam.startDate} navigateTo={`/exams/${exam.id}/welcome/${search}`} />
      </div>
    </BackgroundE4Z>
  );
}
