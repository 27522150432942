import { observer } from 'mobx-react-lite';
import React from 'react';
import _ from 'lodash/fp';

import { useChatStore, useUserStore } from '../../../hooks/store';
import Messages from '../Messages/Messages';

const ParticipantChat: React.FunctionComponent = observer(() => {
  const chatStore = useChatStore();
  const userStore = useUserStore();

  const { host } = userStore;

  const clearMessages = () => {
    if (_.isEmpty(host)) return;

    chatStore.clearUnreadMessages(host.uid);
  };

  return (
    <div className="flex flex-col h-full" onFocus={clearMessages} onClick={clearMessages}>
      <div className="flex-1">
        {!_.isEmpty(host)
        && (
        <Messages
          messageList={chatStore.hostMessagesToMe}
          onSendMessage={(msg) => chatStore.sendMessageToPeer(msg, host.uid)}
          localUid={userStore.localUser.uid}
          isHost={false}
        />
        )}
      </div>
    </div>
  );
});

export default ParticipantChat;
