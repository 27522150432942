import _ from 'lodash/fp';
import React from 'react';

type Props = {
  volume: number
  slices?: number
}

const VolumeIndicator: React.FunctionComponent<Props> = ({ volume, slices = 50 }: Props) => {
  const isActive = (slice: number) => {
    const sliceVolume = (slice * 100) / slices;
    return sliceVolume <= volume;
  };

  return (
    <div className="w-full h-6 flex flex-row space-x-0.5 bg-gray-200 shadow-inner rounded overflow-hidden">
      {_.map((slice) => (
        <span className={`flex-1 h-full w-full ${isActive(slice) ? 'bg-blue' : ''}`} key={slice} />
      ), _.range(0, slices))}
    </div>
  );
};

export default VolumeIndicator;
