import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExamIncidenceType } from '../../../channels/exam_incidences_channel';
import '../../../services/i18n/config';
import { ModalEndExamData } from '../../../types';
import BlueButton from '../../Button/BlueButton/BlueButton';
import RedButton from '../../Button/RedButton/RedButton';
import Base, { BaseModalProps } from '../Base/Base';

type Props = BaseModalProps & {
  data: ModalEndExamData
}

const EndExam: React.FunctionComponent<Props> = ({ data, onClose }: Props) => {
  const { t } = useTranslation();

  const textMapping: Record<ExamIncidenceType, string> = {
    KO_ID_VALIDATION: t('id_validation_failed'),
    KO_CHROME_EXTENSION: t('ko_chrome_extension'),
    COPY_EXTERNAL_TEXT: t('copy_external_text'),
    FILE_DOWNLOADED: t('file_downloaded'),
    PASTE_TEXT: t('paste_text'),
    TAB_ACTIVATE: t('tab_activate'),
    DROP_LVL_ATTENTION: t('drop_lvl_attention'),
    ABSENT_PARTICIPANT: t('absent_participant'),
    PARTICIPANT_NOT_ALONE: t('participant_not_alone'),
    NEW_TAB_WINDOW: t('other_tab_active'),
    STOP_SHARING_DESKTOP: t('stop_sharing_desktop'),
    USER_DROPPED: t('abandoned_exam'),
    CAMERA_STOPPED: t('camera_stopped'),
    MICROPHONE_STOPPED: t('microphone_stopped'),
  };

  const incidences = data.incidences[0].map((i) => (
    <li key={i.id}>
      {`${t(textMapping[i.type])} (${data.incidences[1][i.type].length})`}
    </li>
  ));

  return (
    <Base onClose={onClose}>
      <div className="flex flex-col space-y-10 mx-8">
        <div className="font-bold text-xl">
          {t('participants_have_completed_the', { participants: data.finishedParticipantsCount, exam: data.examName })}
        </div>
        <div>
          <p>
            {data.incidences[0].length > 0 && t('you_had_events_caused_by', { events: data.incidences[0].length })}
          </p>
          <ul className="text-xs pl-3">
            {incidences}
          </ul>
        </div>
        <div>
          <p>{t('your_recording_is_being_processed')}</p>
        </div>
        <div className="flex items-center justify-center space-x-2">
          <BlueButton onClick={() => {
            onClose();
            data.onRestart();
          }}
          >
            {t('restart_session')}
          </BlueButton>
          <RedButton onClick={() => {
            onClose();
            data.onEnd();
          }}
          >
            {t('end_session')}
          </RedButton>
        </div>
      </div>
    </Base>
  );
};

export default EndExam;
