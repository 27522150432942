import React from 'react';
import { observer } from 'mobx-react-lite';

import { useModalStore } from '../../hooks/store';

import Snapshot from './Snapshot/Snapshot';
import ExitExam from './ExitExam/ExitExam';
import EndExam from './EndExam/EndExam';
import OpenedExam from './OpenedExam/OpenedExam';
import ShareScreenTutorial from './ShareScreenTutorial/ShareScreenTutorial';
import ChromeExtension from './ChromeExtension/ChromeExtension';
import Comment from './Comment/Comment';

const ModalContainer = observer(() => {
  const store = useModalStore();

  if (!store.isAnyOpen) {
    return null;
  }

  return (
    <>
      {store.isOpen('SCREENSHOT')
        && (
          <Snapshot
            data={store.getModalData('SCREENSHOT')}
            onClose={() => store.closeModal('SCREENSHOT')}
          />
        )}

      {store.isOpen('COMMENT')
        && (
          <Comment
            data={store.getModalData('COMMENT')}
            onClose={() => store.closeModal('COMMENT')}
          />
        )}

      {store.isOpen('EXIT_EXAM')
        && (
          <ExitExam
            data={store.getModalData('EXIT_EXAM')}
            onClose={() => store.closeModal('EXIT_EXAM')}
          />
        )}

      {store.isOpen('END_EXAM')
        && (
          <EndExam
            data={store.getModalData('END_EXAM')}
            onClose={() => store.closeModal('END_EXAM')}
          />
        )}
      {store.isOpen('OPENED_EXAM')
      && (
        <OpenedExam
          data={store.getModalData('OPENED_EXAM')}
          onClose={() => store.closeModal('OPENED_EXAM')}
        />
      )}
      {store.isOpen('SHARE_SCREEN_TUTORIAL')
      && (
        <ShareScreenTutorial
          data={store.getModalData('SHARE_SCREEN_TUTORIAL')}
          onClose={() => store.closeModal('SHARE_SCREEN_TUTORIAL')}
        />
      )}
      {store.isOpen('CHROME_EXTENSION')
      && (
        <ChromeExtension
          data={store.getModalData('CHROME_EXTENSION')}
          onClose={() => store.closeModal('CHROME_EXTENSION')}
        />
      )}
    </>
  );
});

export default ModalContainer;
