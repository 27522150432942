import { ICameraVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng';
import _ from 'lodash/fp';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../services/i18n/config';
import { useDeviceStore, useUserStore } from '../../hooks/store';
import Dropdown from '../Dropdown/Dropdown';
import RenderPlayer from '../Players/RenderPlayer/RenderPlayer';
import RoundedCard from '../RoundedCard/RoundedCard';
import VolumeIndicator from '../VolumeIndicator/VolumeIndicator';

type Props = {
  loading: boolean,
  extensionInstalled: boolean,
}

const CheckAudioAndVideo: React.FunctionComponent<Props> = observer(({
  loading, extensionInstalled,
} : Props) => {
  const { t } = useTranslation();
  const deviceStore = useDeviceStore();
  const userStore = useUserStore();

  const [volume, setVolume] = React.useState(0);
  const [camera, setCamera] = React.useState<MediaDeviceInfo>();
  const [microphone, setMicrophone] = React.useState<MediaDeviceInfo>();
  const video = userStore.localUser.videoTrack as ICameraVideoTrack;
  const audio = userStore.localUser.audioTrack as IMicrophoneAudioTrack;

  React.useEffect(() => {
    const loadDevices = async () => {
      await userStore.rtc.createCameraLocalTracks();
      await deviceStore.init();
      const selectedCamera = deviceStore.cameraList.find(
        (cam: MediaDeviceInfo) => cam.deviceId === sessionStorage.cameraId,
      ) || _.first(deviceStore.cameraList);

      const selectedMic = deviceStore.microphoneList.find(
        (mic: MediaDeviceInfo) => mic.deviceId === sessionStorage.microphoneId,
      ) || _.first(deviceStore.microphoneList);

      setCamera(selectedCamera);
      setMicrophone(selectedMic);
    };
    loadDevices();
  }, [deviceStore, userStore]);

  React.useEffect(() => {
    if (_.isNull(audio)) return;

    const intervalId = setInterval(() => {
      setVolume(audio.getVolumeLevel() * 100);
    }, 100);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [audio]);

  const changeCamera = (id: string): void => {
    const selectedCamera = _.find({ deviceId: id }, deviceStore.cameraList);
    setCamera(selectedCamera);
    deviceStore.changeCamera(selectedCamera.deviceId);
  };

  const changeMicrophone = (id: string): void => {
    const selectedMicrophone = _.find({ deviceId: id }, deviceStore.microphoneList);
    setMicrophone(selectedMicrophone);
    deviceStore.changeMicrophone(selectedMicrophone.deviceId);
  };

  return (
    <RoundedCard className="flex flex-col h-full w-full">
      <div className="w-full flex bg-white items-center justify-center">
        <div className="mt-6 mb-3 text-center">
          <p className="font-bold text-gray-700">{t('get_you_setup')}</p>
          <p>{t('see_and_hear_you')}</p>
          <p>{t('you_can_choose_your_preferred_settings_in_the_drop_down_menu_below')}</p>
          {
              !loading && !extensionInstalled && userStore.isLocalParticipant()
                ? (
                  <div className="mt-3 mb-3 text-center text-sm font-size-12">
                    <p>
                      {t('chrome_extension_missing')}
                      <a target="_blank" href="https://chrome.google.com/webstore/detail/exams4zoom/ikgahaiijeoaeeknbphnnjklapmpojpg?hl=en" className="text-blue" rel="noreferrer">
                        {t('here')}
                        !
                      </a>
                    </p>
                  </div>
                )
                : null
          }
        </div>
      </div>
      <div className="w-full flex-1 flex bg-white items-center justify-center overflow-y-scroll pb-20">
        <div className="w-[450px] flex flex-col">
          <div className="flex items-center justify-center h-[325px] overflow-hidden rounded shadow-md bg-black">
            {_.isNil(video)
              ? (
                <div className="text-white text-center font-bold text-lg px-12">
                  {t('we_need_authorization_to_access_your_camera_and_audio')}
                </div>
              )
              : <RenderPlayer track={video} className="w-full h-full" />}
          </div>
          <div className="mt-2">
            <VolumeIndicator volume={volume} />
          </div>
          <div className="mt-6 flex flex-row space-x-2">
            <div className="flex-1">
              <Dropdown
                mode="LIGHT"
                value={
                  !_.isNil(camera)
                    ? { value: camera?.deviceId, label: `${t('camera')}: ${camera?.label}` }
                    : undefined
                }
                onChange={(cam) => changeCamera(cam.value)}
                options={_.map((cam) => ({ value: cam.deviceId, label: cam.label }),
                  deviceStore.cameraList)}
              />
            </div>
            <div className="flex-1">
              <Dropdown
                mode="LIGHT"
                value={
                  !_.isNil(microphone)
                    ? { value: microphone?.deviceId, label: `${t('audio')}: ${microphone?.label}` }
                    : undefined
                }
                onChange={(mic) => changeMicrophone(mic.value)}
                options={_.map((mic) => ({ value: mic.deviceId, label: mic.label }),
                  deviceStore.microphoneList)}
              />
            </div>
          </div>
        </div>
      </div>
    </RoundedCard>
  );
});

export default CheckAudioAndVideo;
