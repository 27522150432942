import React from 'react';
import { observer } from 'mobx-react-lite';

import { useRoomStore } from '../../hooks/store';
import { RoomExam, RoomUser } from '../../types';
import { useEffectOnce } from '../../hooks/useEffectOnce';

export type RoomProps = {
  exam: RoomExam;
  user: RoomUser;
  route: string;
}

type Props = React.PropsWithChildren<RoomProps>

const Preroom: React.FunctionComponent<Props> = observer(({
  children, exam, user, route,
}: Props) => {
  const roomStore = useRoomStore();
  /* eslint-disable  react-hooks/exhaustive-deps */
  useEffectOnce(() => {
    roomStore.joinPre({ exam, user }, route);
    return () => {
      roomStore.leave();
    };
  });
  /* eslint-enable  react-hooks/exhaustive-deps */

  return <div className="h-full">{children}</div>;
});

export default Preroom;
