import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import '../../services/i18n/config';
import { useRoomStore, useToastStore } from '../../hooks/store';
import ExamsLogger from '../../logger';
import { RoomExam, RoomUser } from '../../types';
import { useEffectOnce } from '../../hooks/useEffectOnce';

export type RoomProps = {
  exam: RoomExam;
  user: RoomUser;
  route: string
}

type Props = React.PropsWithChildren<RoomProps>

const Room: React.FunctionComponent<Props> = observer(({
  children, exam, user, route,
}: Props) => {
  const { t } = useTranslation();
  const toastStore = useToastStore();

  const roomStore = useRoomStore();

  /* eslint-disable  react-hooks/exhaustive-deps */
  useEffectOnce(() => {
    roomStore
      .join({ exam, user }, route)
      .then(() => {
        toastStore.addToast(`${t('joined_successfully_to_room')} ${exam.name}`);
      })
      .catch((err) => {
        ExamsLogger.error(err);
        toastStore.addToast(`${t('failed_to_join_the_room')} ${exam.name}`);
      });
    return () => {
      roomStore.leave();
    };
  });
  /* eslint-enable  react-hooks/exhaustive-deps */

  return <div className="h-screen">{children}</div>;
});

export default Room;
