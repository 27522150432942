/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import Logo from '../Logo/Logo';
import ExamName from '../ExamName/ExamName';
import ViewModeDropdown from '../ViewModeDropdown/ViewModeDropdown';
import SpeakingTo from '../SpeakingTo/SpeakingTo';
import HostToolTip from '../../Tooltip/HostToolTip/HostToolTip';

type Props = {
  examName: string,
  hideSpeakingToAndDropdown?: boolean,
  numberOfParticipants?: number,
  duration?: number,
  idValidation?: boolean,
  uploadFiles?: boolean,
}

const HostTopBar: React.FunctionComponent<Props> = ({
  examName,
  hideSpeakingToAndDropdown = false,
  numberOfParticipants = 0,
  duration = 0,
  idValidation = false,
  uploadFiles = false,
}: Props) => (
  <div className="h-10 w-full flex items-center">
    <Logo />
    <div className="px-2 flex flex-grow justify-between">
      <ExamName name={examName} />
      {
            !hideSpeakingToAndDropdown && <SpeakingTo />
          }
      <div className="flex px-2">
        {
              !hideSpeakingToAndDropdown && (
              <div className="flex justify-end mr-3">
                <ViewModeDropdown />
              </div>
              )
            }
        <HostToolTip
          examName={examName}
          numberOfParticipants={numberOfParticipants}
          duration={duration}
          idValidation={idValidation}
          uploadFiles={uploadFiles}
        />
      </div>
    </div>
  </div>
);

export default HostTopBar;
