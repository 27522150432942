import { format } from 'date-fns/fp';
import { observer } from 'mobx-react-lite';
import React from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import '../../../services/i18n/config';
import Icons from '../../../images/icons';
import { useUserStore } from '../../../hooks/store';
import { Incidence as ExamIncidence } from '../../../stores/app/room/incidence';
import { ExamIncidenceType } from '../../../channels/exam_incidences_channel';

type Props = {
  incidence: ExamIncidence
}

const iconMapping: Record<ExamIncidenceType, React.ReactNode> = {
  KO_ID_VALIDATION: <Icons.Alert className="h-3 text-black" />,
  KO_CHROME_EXTENSION: <Icons.Alert className="h-3 text-black" />,
  TAB_ACTIVATE: <Icons.Alert className="h-3 text-black" />,
  COPY_EXTERNAL_TEXT: <Icons.Alert className="h-3 text-black" />,
  FILE_DOWNLOADED: <Icons.Alert className="h-3 text-black" />,
  PASTE_TEXT: <Icons.Alert className="h-3 text-black" />,
  DROP_LVL_ATTENTION: <Icons.Alert className="h-3 text-black" />,
  ABSENT_PARTICIPANT: <Icons.Alert className="h-3 text-black" />,
  PARTICIPANT_NOT_ALONE: <Icons.Alert className="h-3 text-black" />,
  NEW_TAB_WINDOW: <Icons.Alert className="h-3 text-black" />,
  STOP_SHARING_DESKTOP: <Icons.Alert className="h-3 text-black" />,
  USER_DROPPED: <Icons.Alert className="h-3 text-black" />,
  CAMERA_STOPPED: <Icons.Alert className="h-3 text-black" />,
  MICROPHONE_STOPPED: <Icons.Alert className="h-3 text-black" />,
};

const colorMapping: Record<ExamIncidenceType, string> = {
  KO_ID_VALIDATION: 'bg-[#FE5900]',
  KO_CHROME_EXTENSION: 'bg-[#FE5900]',
  COPY_EXTERNAL_TEXT: 'bg-[#FE5900]',
  FILE_DOWNLOADED: 'bg-[#FE5900]',
  PASTE_TEXT: 'bg-[#FE5900]',
  TAB_ACTIVATE: 'bg-[#FE5900]',
  DROP_LVL_ATTENTION: 'bg-[#F2A20C]',
  ABSENT_PARTICIPANT: 'bg-[#FE5900]',
  PARTICIPANT_NOT_ALONE: 'bg-[#FE5900]',
  NEW_TAB_WINDOW: 'bg-[#FE5900]',
  STOP_SHARING_DESKTOP: 'bg-[#FE5900]',
  USER_DROPPED: 'bg-[#FE5900]',
  CAMERA_STOPPED: 'bg-[#FE5900]',
  MICROPHONE_STOPPED: 'bg-[#FE5900]',
};

const Incidence: React.FunctionComponent<Props> = observer(({ incidence }: Props) => {
  const { t } = useTranslation();

  const textMapping: Record<ExamIncidenceType, string> = {
    KO_ID_VALIDATION: t('id_validation_failed'),
    KO_CHROME_EXTENSION: t('ko_chrome_extension'),
    COPY_EXTERNAL_TEXT: t('copy_external_text'),
    FILE_DOWNLOADED: t('file_downloaded'),
    PASTE_TEXT: t('paste_text'),
    TAB_ACTIVATE: t('tab_activate'),
    DROP_LVL_ATTENTION: t('drop_lvl_attention'),
    ABSENT_PARTICIPANT: t('absent_participant'),
    PARTICIPANT_NOT_ALONE: t('participant_not_alone'),
    NEW_TAB_WINDOW: t('other_tab_active'),
    STOP_SHARING_DESKTOP: t('stop_sharing_desktop'),
    USER_DROPPED: t('abandoned_exam'),
    CAMERA_STOPPED: t('camera_stopped'),
    MICROPHONE_STOPPED: t('microphone_stopped'),
  };

  const userStore = useUserStore();

  const user = userStore.user(incidence.uid);

  const formatText = () => {
    const text = `${t(textMapping[incidence.type])}`;
    if (_.isEmpty(user)) return text;

    return `${text} (${_.pipe(_.take(2), _.join(''), _.upperCase)(user.username)})`;
  };

  return (
    <div className="flex flex-row items-center text-2xs text-gray-600 space-x-2" title={user?.username}>
      <div className={`rounded h-6 w-2 ${colorMapping[incidence.type]}`} />
      <div className="flex flex-row items-center space-x-1">
        <span className="block">{format('HH:mm', incidence.timestamp)}</span>
        <hr className="w-3 h-px border-gray-400" />
        {iconMapping[incidence.type]}
        <div className="block">
          {formatText()}
        </div>
      </div>
    </div>
  );
});

export default Incidence;
