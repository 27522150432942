export enum ExamsUserRole {
  ADMIN = 'ADMIN',
  HOST = 'HOST',
  PROCTOR = 'PROCTOR',
  PARTICIPANT = 'PARTICIPANT',
}

const enums = {
  ExamsUserRole,
};

export default enums;
