import _ from 'lodash/fp';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useRoomStore, useUserStore, useIncidenceStore } from '../../../hooks/store';
import HostChat from '../../Chat/HostChat/HostChat';
import Incidences from '../../Incidences/Incidences';
import Participants from '../../Participants/Participants';
import UserPlayer from '../../Players/UserPlayer/UserPlayer';
import Tabs, { TabsName } from '../Tabs/Tabs';

const HostSideBar: React.FunctionComponent = observer(() => {
  const userStore = useUserStore();
  const roomStore = useRoomStore();
  const incidenceStore = useIncidenceStore();

  const active = roomStore.activeTab;

  const changeActiveTab = (tab: TabsName) => {
    roomStore.setActiveTab(tab);
    if (tab === 'events') {
      incidenceStore.markRead();
    }
  };

  return (
    <div className="flex flex-col w-64 h-full">
      {!_.isNil(userStore.host) && (
        <UserPlayer user={userStore.host} className="w-full h-48" focus="PARTICIPANT" />
      )}
      <div className="flex flex-col flex-1 pt-4">
        <Tabs active={active} onChange={changeActiveTab} />
        <div className="pt-2 h-full ">
          {active === 'participants' && <Participants />}
          {active === 'chat' && <HostChat />}
          {active === 'events' && <Incidences />}
        </div>
      </div>
    </div>
  );
});

export default HostSideBar;
