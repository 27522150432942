import { observer } from 'mobx-react-lite';
import { format } from 'date-fns/fp';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

import { useUserStore } from '../../../../hooks/store';
import { ExamsMessage } from '../../../../types';
import UserAvatar from '../../../UserAvatar/UserAvatar';

type Props = {
  message: ExamsMessage
}

const OtherMessage: React.FunctionComponent<Props> = observer(({ message }: Props) => {
  const userStore = useUserStore();
  const user = userStore.user(message.senderId);

  const URLify = (_message: string) => {
    // eslint-disable-next-line no-useless-escape
    const urls = _message.match(/((((https?):\/\/)|(w{3}\.))[\-\w@:%_\+.~#?,&\/\/=]+)/g);
    if (urls) {
      urls.forEach((url) => {
        // eslint-disable-next-line no-param-reassign
        _message = _message.replace(url, `<a class="text-blue underline" target="_blank" href="${url}">${url}</a>`);
      });
    }
    return _message;
  };

  const sanitize = (dirty) => ({
    __html: sanitizeHtml(
      URLify(dirty),
      {
        allowedTags: ['a'],
        allowedAttributes: {
          a: ['href', 'target', 'class'],
        },
      },
    ),
  });

  return (
    <div className="self-start flex flex-row">
      <div className="flex items-end mr-2">
        <UserAvatar user={user} />
      </div>
      <div className="flex flex-col max-w-[70%]">
        <div
          className="bg-gray-700 text-white py-2 px-3 rounded-t-lg rounded-br-lg break-all leading-5"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={sanitize(message.text)}
        />
        <div className="flex flex-row justify-end text-2xs italic space-x-2">
          <span className="block truncate">{user?.username}</span>
          <span className="block">{format('HH:mm', message.timestamp)}</span>
        </div>
      </div>
    </div>
  );
});

export default OtherMessage;
