import React from 'react';
import { Picker } from 'emoji-mart';

import 'emoji-mart/css/emoji-mart.css';
import Popover from '../../../Popover/Popover';
import Icons from '../../../../images/icons';

type Props = {
  onSelect: (emoji: string) => void
}

const EmojiPicker: React.FunctionComponent<Props> = ({ onSelect }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectEmoji = (emoji: any) => {
    onSelect(emoji.native);
  };

  return (
    <Popover
      portal
      content={(
        <Picker
          native
          onSelect={selectEmoji}
          color="#2DABFF"
          showPreview={false}
          showSkinTones={false}
          useButton={false}
          emojiTooltip
        />
      )}
    >
      <div className="flex items-center justify-center text-gray-400">
        <Icons.Emoji className="cursor-pointer h-6" />
      </div>
    </Popover>
  );
};

export default EmojiPicker;
