import { observable, action, makeObservable } from 'mobx';
import { v4 as uuid } from 'uuid';
import _ from 'lodash/fp';

import { Resetable } from '../../interfaces/resetable';
import ExamsLogger from '../../../logger';

import UIStore from '.';

type Toast = {
  id: string,
  message: string
}

const logPrefix = '[Toast Store]';
class ToastStore implements Resetable {
  uiStore: UIStore

  @observable
  toastQueue: Toast[] = []

  constructor(ui: UIStore) {
    makeObservable(this);
    this.uiStore = ui;
  }

  @action
  addToast(message: string): void {
    ExamsLogger.info(logPrefix, 'Adding toast: ', message);
    this.toastQueue = _.concat(this.toastQueue, {
      id: uuid(),
      message,
    });
  }

  @action
  removeToast(id: string): void {
    ExamsLogger.info(logPrefix, 'Removing toast: ', id);
    this.toastQueue = _.reject({ id }, this.toastQueue);
  }

  @action
  reset(): void {
    this.toastQueue = [];
  }
}

export default ToastStore;
