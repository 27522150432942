import React from "react";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import { ContextRoom } from "../../../types";
import BackgroundE4Z from "../BackgroundE4Z";

export default function Welcome() {
  const { exam, user } = useOutletContext<ContextRoom>();
  const { search } = useLocation();

  return (
    <BackgroundE4Z title="Welcome! We&#39;re here to guide you through your assessment proccess">
      <Link
        className="z-10 w-40 inline-flex items-center justify-center px-3 py-2 border border-transparent text-lg font-bold rounded-lg shadow-sm text-white bg-[#2DABFF] hover:bg-blue-300 focus:outline-none mt-16"
        to={`/exams/${exam.id}/instructions${search}`}
      >
        Next
      </Link>
    </BackgroundE4Z>
  );
};
