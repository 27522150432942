import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../../services/i18n/config';
import { useModalStore } from '../../hooks/store';
import client from '../../services/client';
import incidenceService from '../../services/incidence.service';
import { RoomExam } from '../../types';
import Flash from '../Flash/Flash';
import ParticipantSideBarBeforeExam from '../SideBar/ParticipantSideBar/ParticipantSideBarBeforeExam/ParticipantSideBarBeforeExam';
import ParticipantTopBar from '../TopBar/ParticipantTopBar/ParticipantTopBar';

export type RegistrationProps = {
  nextURL: string;
  exam: RoomExam;
  share_screen_tutorial_img: string;
};

type GetidEnvelopedAppResult = {
  id: string;
  summary: {
    status: string;
  }
}

type Props = React.PropsWithChildren<RegistrationProps>;

// TODO: change backend urls
const Registration: React.FunctionComponent<Props> = ({
  nextURL,
  exam,
  share_screen_tutorial_img,
}: Props) => {
  const { t } = useTranslation();
  const [_message, setMessage] = useState('');
  const [showFlash, setShowFlash] = useState(false);
  const showFlashRef = useRef(showFlash);
  const setShowFlashRef = (data:boolean) => {
    showFlashRef.current = data;
    setShowFlash(data);
  };
  const modalStore = useModalStore();

  const goToNextUrl = () => {
    modalStore.openModal('SHARE_SCREEN_TUTORIAL', {
      onOpen: async () => {
        window.location.href = nextURL;
      },
      image: share_screen_tutorial_img,
      registrationId: true,
    });
  };
  const sendResult = (id: string) => {
    const postData = { id, examId: exam.id };
    client.post('/api/registration/store_result', postData).finally(() => {
      goToNextUrl();
    });
  };

  const avoidStuck = () => {
    document
      .getElementById('targetContainer')
      .addEventListener('DOMNodeInserted', () => {
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const { shadowRoot }: any = document.getElementById('targetContainer').firstChild;
          let helpUser = true;
          const observer = new MutationObserver(() => {
            shadowRoot.querySelector('button#main').addEventListener('click', () => {
              helpUser = false;
              setTimeout(() => {
                if (showFlashRef.current) {
                  setMessage(t('id_validation_mandatory'));
                  setTimeout(() => {
                    setShowFlashRef(false);
                  }, 15000);
                }
              }, 1000);
            });
          });
          observer.observe(shadowRoot.querySelector('button#main'), {
            childList: true,
            attributes: true,
            subtree: true,
            characterData: true,
          });
          shadowRoot.querySelector('button#main').addEventListener('click', () => {
            setTimeout(() => {
              if (helpUser) {
                setShowFlashRef(true);
                setMessage(t('dont_have_id_doc'));
              }
            }, 30000);
          });
        }, 1000);
      });
  };

  const getToken = (retryGet = true) => {
    client
      .get('/api/registration/token')
      .then((response) => {
        let retry = true;
        if (response.data.token) {
          const tkn = response.data.token;
          const targetContainer = document.getElementById(
            'targetContainer',
          ) as HTMLDivElement;
          const config: unknown = {
            apiUrl: 'https://etd-and-partners.getid.ee',
            jwt: tkn,
            containerId: 'targetContainer',
            flowName: 'getid-doc-selfie-liveness',
            injectCSS:
              '#getid-main .getid-header-line__powered {display: none !important;}',
            onFail: () => {
              if (retry) {
                // @ts-ignore
                // FIXME: fix this ts ignore
                window.getidWebSdk.init(config);
                retry = false;
              }
            },
            onVerificationComplete: (envelopedApplicationResult:GetidEnvelopedAppResult) => {
              const { id, summary } = envelopedApplicationResult;
              if (summary.status !== 'approved') {
                incidenceService.create({
                  examId: exam.id,
                  type: 'KO_ID_VALIDATION',
                });
              }
              sendResult(id);
              targetContainer.style.display = 'none';
            },
          };
          avoidStuck();
          // @ts-ignore
          // FIXME: fix this ts ignore
          window.getidWebSdk.init(config);
        } else if (retryGet) {
          getToken(false);
        } else {
          incidenceService.create({
            examId: exam.id,
            type: 'KO_ID_VALIDATION',
          });
          goToNextUrl();
        }
      })
      .catch(() => {
        incidenceService.create({
          examId: exam.id,
          type: 'KO_ID_VALIDATION',
        });
        goToNextUrl();
      });
  };

  const injectScript = (src: string, id: string, callback: () => void) => {
    const script = document.createElement('script');
    script.id = id.toString();
    script.src = src;
    script.async = true;
    script.onload = () => {
      callback();
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    const scriptId = 'getid-sdk';
    const scriptUrl = 'https://cdn.getid.cloud/sdk/getid-web-sdk-v6.min.js';
    if (!document.getElementById('getid-sdk')) {
      injectScript(scriptUrl, scriptId, getToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-black flex flex-col">
      <ParticipantTopBar
        examName={exam.name}
        duration={exam.duration}
        takeScreenshot={exam.takeScreenshot}
        uploadFiles={exam.uploadFiles}
        recordEnabled={exam.recordEnabled}
        isUnattended={exam.isUnattended}
      />
      <div className="flex flex-row h-full">
        <div className="h-screen">
          <ParticipantSideBarBeforeExam registrationEnabled={exam.identityRegistration} />
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex bg-dark rounded-sm p-2 h-full">
            <div className="flex flex-col flex-1 w-2/2">
              <div className="main-wrapper">
                <Flash show={showFlash} message={_message} />
                <div id="targetContainer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
