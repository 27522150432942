import React from 'react';

import ArrowButton from '../../../../ArrowButton/ArrowButton';

type Props = {
  onClick: () => void
  className?: string
}

const LeftButton: React.FunctionComponent<Props> = ({ onClick, className = '' }: Props) => (
  <div className={`absolute flex flex-col h-full items-center justify-center -left-2 top-0 ${className}`}>
    <ArrowButton onClick={onClick} stickDirection="left" arrowDirection="left" />
  </div>
);

export default LeftButton;
