import React, { ReactNode } from "react";
import backgroundLogo from "../../assets/images/background_e4z.svg";
import logo from "../../assets/images/logo_e4z.svg";

type Props = {
    title: string | ReactNode,
    children?: ReactNode
}

export default function BackgroundE4Z({ title, children }: Props) {
    return (
        <div className="min-h-screen flex items-center justify-center bg-blue-dark py-12 px-4 sm:px-6 lg:px-8">
          <img
            alt="Exams For Zoom"
            className="absolute h-full"
            src={backgroundLogo}
          />
          <div className="max-w-[50%] w-full">
            <div className="flex items-center justify-center">
              <img alt="Exams For Zoom" className="w-[176px]" src={logo} />
            </div>
            <div className="flex items-center justify-center">
              <h2 className="mt-8 z-10 text-white font-size-25 text-center">
                {title}
              </h2>
            </div>
            <div className="flex items-center justify-center">
              {children}
            </div>
          </div>
        </div>
      );
}
