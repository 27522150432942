import React from 'react';

import BottomBar from '../BottomBar/BottomBar';
import SideBar from '../SideBar/SideBar';
import VideoCall from '../VideoCall/VideoCall';
import TopBar from '../TopBar/TopBar';

const Exam: React.FunctionComponent = () => (
  <div className="w-full h-full flex flex-col bg-black">
    <TopBar />
    <div className="flex flex-row flex-1">
      <SideBar />
      <VideoCall />
    </div>
    <BottomBar />
  </div>
);

export default Exam;
