import AgoraRTC, * as agoraRtcSdkNg from 'agora-rtc-sdk-ng';
import _ from 'lodash/fp';
import {
  action, computed, makeObservable, observable,
} from 'mobx';

import type { JoinOptions, RoomExam } from '../../../types';
import ExamsLogger from '../../../logger';
import { Resetable } from '../../interfaces/resetable';
import AppStore from '..';
import UserStore from '../room/user';
import compare from '../../../lib/compare';
import incidenceService from '../../../services/incidence.service';
import RTMStore, { createActionMessage } from '../rtm';

import RTCStore from '.';

type SpeakTo = 'all' | 'none' | agoraRtcSdkNg.UID
type ListenTo = 'all' | 'none' | agoraRtcSdkNg.UID

const logPrefix = '[Camera Store]';

class CameraStore implements Resetable {
  rtcStore!: RTCStore

  client: agoraRtcSdkNg.IAgoraRTCClient = null

  @observable
  joined = false

  @observable
  localUid: agoraRtcSdkNg.UID = null

  @observable
  localAudioTrack: agoraRtcSdkNg.IMicrophoneAudioTrack = null

  @observable
  localVideoTrack: agoraRtcSdkNg.ICameraVideoTrack = null

  // We use an array so users are sorted in joining order
  @observable
  remoteUsers: agoraRtcSdkNg.IAgoraRTCRemoteUser[] = []

  @observable
  speakTo: SpeakTo = 'all'

  @observable
  listenTo: ListenTo = 'all'

  constructor(rtc: RTCStore) {
    makeObservable(this);
    this.rtcStore = rtc;
  }

  get appStore(): AppStore {
    return this.rtcStore.appStore;
  }

  get userStore(): UserStore {
    return this.appStore.roomStore.userStore;
  }

  get rtm(): RTMStore {
    return this.appStore.roomStore.rtm;
  }

  @computed
  get callOptions(): JoinOptions {
    return this.rtcStore.callOptions;
  }

  @computed
  get exam(): RoomExam {
    return this.appStore.roomStore.info.exam;
  }

  @action
  reset(): void {
    this.remoteUsers = [];
    this.localAudioTrack = null;
    this.localVideoTrack = null;
    this.localUid = null;

    this.client.removeAllListeners();
    this.client = null;
    this.joined = false;
    this.speakTo = 'all';
    this.listenTo = 'all';
  }

  @action
  init(): void {
    if (!_.isNil(this.client)) return;

    this.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
  }

  @action
  setLocalUid(uid: agoraRtcSdkNg.UID): void {
    this.localUid = uid;
  }

  @action
  setLocalAudioTrack(track: agoraRtcSdkNg.IMicrophoneAudioTrack): void {
    this.localAudioTrack = track;
  }

  @action
  setLocalVideoTrack(track: agoraRtcSdkNg.ICameraVideoTrack): void {
    this.localVideoTrack = track;
  }

  @action
  setJoined(val: boolean): void {
    this.joined = val;
  }

  @action
  setSpeakTo(to: SpeakTo): void {
    this.speakTo = to;
  }

  isSpeakingTo(uid: agoraRtcSdkNg.UID): boolean {
    if (this.speakTo === 'all') return true;
    if (this.speakTo === 'none') return false;

    return compare.equalAgoraUID(uid, this.speakTo);
  }

  @action
  setListenTo(to: ListenTo): void {
    this.listenTo = to;
  }

  isListeningTo(uid: agoraRtcSdkNg.UID): boolean {
    if (this.listenTo === 'all') return true;
    if (this.listenTo === 'none') return false;

    return compare.equalAgoraUID(uid, this.listenTo);
  }

  @action
  async createLocalTracks(): Promise<void> {
    const [audio, video] = await AgoraRTC.createMicrophoneAndCameraTracks(
      { microphoneId: sessionStorage.microphoneId }, { cameraId: sessionStorage.cameraId },
    );
    this.setLocalAudioTrack(audio);
    this.setLocalVideoTrack(video);
  }

  @action
  async muteLocalAudioTracks(): Promise<void> {
    this.localAudioTrack.setMuted(this.exam.disableAudio);
  }

  @action
  async startCall(options: JoinOptions): Promise<void> {
    ExamsLogger.info(logPrefix, 'Starting call: ', options);

    this.init();

    await this.client.join(
      this.appStore.appId,
      options.channel,
      options.token ?? null,
      options.uid,
    );

    this.setLocalUid(options.uid);

    this.listenEvents();

    await this.createLocalTracks();

    await this.client.publish([this.localAudioTrack, this.localVideoTrack]);

    await this.muteLocalAudioTracks();
  }

  @action
  async leaveCall(): Promise<void> {
    ExamsLogger.info(logPrefix, 'Leaving call:', this.callOptions);

    this.localAudioTrack.close();
    this.localVideoTrack.close();

    await this.client.leave();

    this.setJoined(false);
  }

  private listenEvents(): void {
    this.client.on('user-joined', (user) => {
      ExamsLogger.info(logPrefix, 'user-joined', user);
      if (this.userStore.isLocalHost()) {
        this.addRemoteUser(user);
        return;
      }
      if (this.userStore.isHost(user.uid)) {
        this.addRemoteUser(user);
      }
    });

    this.client.on('user-left', (user) => {
      ExamsLogger.info(logPrefix, 'user-left', user);
    });

    this.client.on('user-published', async (user, media) => {
      ExamsLogger.info(logPrefix, 'user-published', media, user);
      if (this.isLocal(user.uid)) return;

      const subscribeToUser = async () => {
        await this.client.subscribe(user, media);
        if (media === 'audio') {
          user.audioTrack?.play();
        }
        if (media === 'video') {
          this.addOrUpdateRemoteUser(user);
        }
      };

      if (this.userStore.isLocalHost()) {
        await subscribeToUser();

        if (!this.isListeningTo(user.uid)) user.audioTrack?.stop();

        if (!this.isSpeakingTo(user.uid)) await this.remoteStopAudio(user.uid);

        return;
      }
      if (this.userStore.isHost(user.uid)) {
        await subscribeToUser();
      }
    });

    this.client.on('user-unpublished', async (user, media) => {
      ExamsLogger.info(logPrefix, 'user-unpublished', media, user);
    });

    AgoraRTC.onCameraChanged = (info) => {
      if (info.state === 'INACTIVE') {
        this.sendAlertCameraStopped();
        this.appStore.roomStore.controlAnalysis('pause');
      } else {
        this.appStore.roomStore.controlAnalysis('play');
      }
    };

    let miconce = true;
    AgoraRTC.onMicrophoneChanged = (info) => {
      if (info.state === 'INACTIVE') {
        if (miconce) {
          this.sendAlertMicrophoneStopped();
          this.appStore.roomStore.controlAnalysis('pause');
          miconce = false;
          setTimeout(() => { miconce = true; }, 5000);
        }
      } else {
        this.appStore.roomStore.controlAnalysis('play');
      }
    };
  }

  isLocal(uid: agoraRtcSdkNg.UID): boolean {
    return compare.equalAgoraUID(this.localUid, uid);
  }

  getRemoteUser(uid: agoraRtcSdkNg.UID): agoraRtcSdkNg.IAgoraRTCRemoteUser {
    return _.find((user) => compare.equalAgoraUID(user.uid, uid), this.remoteUsers);
  }

  existRemoteUser(uid: agoraRtcSdkNg.UID): boolean {
    return !_.isEmpty(this.getRemoteUser(uid));
  }

  @action
  addRemoteUser(user: agoraRtcSdkNg.IAgoraRTCRemoteUser): void {
    if (this.existRemoteUser(user.uid)) return;

    this.remoteUsers = _.concat(this.remoteUsers, user);
  }

  @action
  updateRemoteUser(user: agoraRtcSdkNg.IAgoraRTCRemoteUser): void {
    if (!this.existRemoteUser(user.uid)) return;

    this.remoteUsers = _.map((remote) => {
      if (remote.uid === user.uid) {
        return user;
      }
      return remote;
    }, this.remoteUsers);
  }

  @action
  removeRemoteUser(uid: agoraRtcSdkNg.UID): void {
    if (!this.existRemoteUser(uid)) return;

    this.remoteUsers = _.reject((user) => compare.equalAgoraUID(user.uid, uid), this.remoteUsers);
  }

  @action
  addOrUpdateRemoteUser(user: agoraRtcSdkNg.IAgoraRTCRemoteUser): void {
    if (this.existRemoteUser(user.uid)) {
      this.updateRemoteUser(user);
    } else {
      this.addRemoteUser(user);
    }
  }

  @action
  playAllAudio(): void {
    ExamsLogger.info(logPrefix, 'Playing all remote audio');
    _.forEach((user) => {
      user.audioTrack?.play();
    }, this.remoteUsers);
    this.setListenTo('all');
  }

  @action
  stopAllAudio(): void {
    ExamsLogger.info(logPrefix, 'Stop all remote audio');
    _.forEach((user) => {
      user.audioTrack?.stop();
    }, this.remoteUsers);
    this.setListenTo('none');
  }

  @action
  playAudio(uid: agoraRtcSdkNg.UID): void {
    if (!this.existRemoteUser(uid)) return;

    ExamsLogger.info(logPrefix, 'Playing audio from remote user with uid: ', uid);
    const remote = this.getRemoteUser(uid);
    remote.audioTrack?.play();
    this.setListenTo(uid);
  }

  @action
  playOnlyOneAudio(uid: agoraRtcSdkNg.UID): void {
    this.stopAllAudio();

    this.playAudio(uid);
  }

  @action
  stopAudio(uid: agoraRtcSdkNg.UID): void {
    if (!this.existRemoteUser(uid)) return;

    ExamsLogger.info(logPrefix, 'Stoping audio from remote with uid: ', uid);
    const remote = this.getRemoteUser(uid);
    remote.audioTrack?.stop();
  }

  @action
  async remotePlayAllAudio(): Promise<void> {
    const message = createActionMessage({ type: 'ENABLE_HOST_AUDIO' });
    await this.rtm.channel?.sendMessage(message);
    this.setSpeakTo('all');
  }

  @action
  async remoteStopAllAudio(): Promise<void> {
    const message = createActionMessage({ type: 'DISABLE_HOST_AUDIO' });
    await this.rtm.channel?.sendMessage(message);
    this.setSpeakTo('none');
  }

  @action
  async remotePlayAudio(uid: agoraRtcSdkNg.UID): Promise<void> {
    if (!this.existRemoteUser(uid)) return;

    const message = createActionMessage({ type: 'ENABLE_HOST_AUDIO' });
    await this.rtm.client?.sendMessageToPeer(message, _.toString(uid));
    this.setSpeakTo(uid);
  }

  @action
  async remotePlayOnlyOneAudio(uid: agoraRtcSdkNg.UID): Promise<void> {
    await this.remoteStopAllAudio();

    await this.remotePlayAudio(uid);
  }

  @action
  async remoteStopAudio(uid: agoraRtcSdkNg.UID): Promise<void> {
    if (!this.existRemoteUser(uid)) return;

    const message = createActionMessage({ type: 'DISABLE_HOST_AUDIO' });
    await this.rtm.client?.sendMessageToPeer(message, _.toString(uid));
  }

  @action
  async sendAlertCameraStopped(): Promise<void> {
    incidenceService.create({
      examId: this.exam.id,
      type: 'CAMERA_STOPPED',
    });
  }

  @action
  async sendAlertMicrophoneStopped(): Promise<void> {
    incidenceService.create({
      examId: this.exam.id,
      type: 'MICROPHONE_STOPPED',
    });
  }
}

export default CameraStore;
