import { UID } from 'agora-rtc-sdk-ng';
import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../../services/i18n/config';
import { ExamsMessage } from '../../../types';

import InputMessage from './InputMessage/InputMessage';
import Message from './Message/Message';

type Props = {
  messageList: ExamsMessage[]
  onSendMessage: (message: string) => void
  localUid: UID
  isHost: boolean
}

const Messages: React.FunctionComponent<Props> = (
  {
    messageList, onSendMessage, localUid, isHost,
  }: Props,
) => {
  const { t } = useTranslation();
  const messagesEndRef = React.useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  return (
    <div className="bg-gray-100 flex flex-col h-full w-full">
      <div className="relative flex-1 h-full">
        <div className="absolute inset-0 overflow-x-hidden overflow-y-auto p-3 flex flex-col space-y-3">
          { !messageList.length && (
            <div className="text-center text-sm text-gray-400 font-semibold mt-4">
              <p>
                {isHost ? t('you_can_start_a_chat_with_your_host') : t('you_can_start_a_chat_with_your_participants')}
              </p>
            </div>
          )}
          {messageList.map((message) => (
            <Message key={message.timestamp} message={message} localUid={localUid} />
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="p-3">
        <InputMessage onSend={onSendMessage} />
      </div>
    </div>
  );
};

export default Messages;
