import { observer } from 'mobx-react-lite';
import React from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import '../../../services/i18n/config';
import { useModalStore, useRoomStore, useToastStore } from '../../../hooks/store';
import { Loading } from '../../Loading/Loading';
import Icons from '../../../images/icons';
import screenshot from '../../../lib/screenshot';
import snapshotService from '../../../services/snapshot.service';

type Props = {
  disabled?: boolean;
}

const Snapshot: React.FunctionComponent<Props> = observer(({ disabled }: Props) => {
  const { t } = useTranslation();
  const modalStore = useModalStore();
  const roomStore = useRoomStore();
  const toastStore = useToastStore();

  const [loading, setLoading] = React.useState(false);

  const onSubmitSnaphot = async (image: string, comment: string): Promise<void> => {
    try {
      // await snapshotService.create({ image, comment, examId: roomStore.info.exam.id });
      toastStore.addToast(t('successfully_uploaded_snapshot'));
    } catch (e) {
      toastStore.addToast(t('error_uploading_snapshot'));
    }
  };

  const openScreenshotModal = async (): Promise<void> => {
    setLoading(true);
    const image = await screenshot.makeScreenshot();
    if (!_.isNil(image)) {
      modalStore.openModal('SCREENSHOT', { image, onSubmit: onSubmitSnaphot });
    }
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div data-test-id="snapshot-btn" className={`text-gray-250 ${!disabled && 'cursor-pointer'} flex flex-col items-center justify-between`} onClick={!disabled ? openScreenshotModal : undefined}>
      <Icons.Snapshot className="h-6" />
      <span className="text-xs text-center leading-4">{t('snapshot')}</span>
    </div>
  );
});

export default Snapshot;
