import type { UID } from 'agora-rtc-sdk-ng';
import AgoraRTM, {
  RtmClient, RtmChannel, RtmTextMessage,
} from 'agora-rtm-sdk';
import _ from 'lodash/fp';
import {
  action, makeObservable, observable, runInAction,
} from 'mobx';

import AppStore from '..';
import ExamsLogger from '../../../logger';
import type {
  JoinOptions, ExamsMessage, ChannelUser, MessageAction,
} from '../../../types';
import { Resetable } from '../../interfaces/resetable';
import ChatStore from '../room/chat';
import UserStore from '../room/user';
import ActionStore from '../room/action';
import NotifyStore from '../ui/notify';

const logPrefix = '[RTM Store]';

export type ActionMessage = {
  type: MessageAction
  params?: Record<string, unknown>
}

export const createTextMessage = (message: string): RtmTextMessage => ({
  messageType: 'TEXT',
  text: message,
});

export const createActionMessage = (message: ActionMessage): RtmTextMessage => ({
  messageType: 'TEXT',
  text: JSON.stringify(message),
});

export const parseMessage = (message: RtmTextMessage): [boolean, string | ActionMessage] => {
  try {
    const text = JSON.parse(message.text) as ActionMessage;
    return [true, text as ActionMessage];
  } catch (e) {
    return [false, message.text as string];
  }
};

class RTMStore implements Resetable {
  appStore: AppStore

  client: RtmClient = null

  channel: RtmChannel = null

  @observable
  joined = false

  @observable
  options: JoinOptions

  @observable
  channelUsers: Record<string, ChannelUser> = {}

  @observable
  messages: ExamsMessage[] = []

  constructor(app: AppStore) {
    makeObservable(this);
    this.appStore = app;
  }

  get userStore(): UserStore {
    return this.appStore.roomStore.userStore;
  }

  get notifyStore(): NotifyStore {
    return this.appStore.uiStore.notify;
  }

  get chatStore(): ChatStore {
    return this.appStore.roomStore.chatStore;
  }

  get actionStore(): ActionStore {
    return this.appStore.roomStore.actionStore;
  }

  @action
  initClient(): void {
    if (!_.isNil(this.client)) return;
    // There is no alternative to createInstance even though it is marked as deprecated
    this.client = AgoraRTM.createInstance(this.appStore.appId);
  }

  @action
  initChannel(): void {
    if (!_.isNil(this.channel)) return;

    this.channel = this.client.createChannel(this.options.channel);
  }

  @action
  reset(): void {
    this.joined = false;

    this.channel.removeAllListeners();
    this.channel = null;

    this.client.removeAllListeners();
    this.client = null;

    this.messages = [];
    this.channelUsers = {};
  }

  @action
  addMessage(message: ExamsMessage): void {
    this.messages = _.concat(this.messages, message);
  }

  existChannelUser(uid: string): boolean {
    const user = _.get(uid, this.channelUsers);
    return !_.isEmpty(user);
  }

  @action
  updateChannelUser(user: ChannelUser): void {
    this.channelUsers = _.set(user.uid, user, this.channelUsers);
  }

  @action
  addChannelUser(user: ChannelUser): void {
    if (this.existChannelUser(user.uid)) return;

    this.channelUsers = _.set(user.uid, user, this.channelUsers);
  }

  @action
  removeChannelUser(uid: string): void {
    if (!this.existChannelUser(uid)) return;

    delete this.channelUsers[uid];
  }

  @action
  async loadChannelUser(uid: UID): Promise<ChannelUser> {
    // This is required because sometimes setting user attributes
    // does not set them even though we wait for response
    let user = null;
    /* eslint-disable no-await-in-loop */
    do {
      user = await this.client.getUserAttributes(_.toString(uid));
    } while (_.isEmpty(user));

    /* eslint-enable no-await-in-loop */
    return user;
  }

  listenClientEvents(): void {
    this.client.on('MessageFromPeer', (message, peerID, messageProps) => {
      ExamsLogger.info(logPrefix, 'MessageFromPeer', message, peerID, messageProps);
      const [isAction, text] = parseMessage(message as RtmTextMessage);
      if (isAction) {
        this.actionStore.executeActionMessage(text as ActionMessage);
      } else {
        const examMessage = {
          senderId: peerID,
          receiverId: this.options.uid,
          text: text as string,
          timestamp: messageProps.serverReceivedTs,
        };
        this.addMessage(examMessage);
        this.notifyNewMessage(examMessage);
        this.chatStore.increaseUnreadMessages(peerID);
      }
    });
    this.client.on('ConnectionStateChanged', (state, reason) => {
      ExamsLogger.info(logPrefix, 'ConnectionStateChanged', state, reason);
    });
  }

  listenChannelEvents(): void {
    this.channel.on('ChannelMessage', (message, memberId, messageProps) => {
      ExamsLogger.info(logPrefix, 'ChannelMessage', message, memberId, messageProps);
      const [isAction, text] = parseMessage(message as RtmTextMessage);
      if (isAction) {
        this.actionStore.executeActionMessage(text as ActionMessage);
      } else {
        const examMessage = {
          senderId: memberId,
          receiverId: null,
          text: text as string,
          timestamp: messageProps.serverReceivedTs,
        };
        this.addMessage(examMessage);
        this.notifyNewMessage(examMessage);
        this.chatStore.increaseUnreadMessages(memberId);
      }
    });
    this.channel.on('MemberJoined', async (memberId) => {
      const user = await this.loadChannelUser(memberId);
      ExamsLogger.info(logPrefix, 'MemberJoined', memberId, user);
      this.updateChannelUser(user);
    });

    this.channel.on('MemberLeft', (memberId) => {
      ExamsLogger.info(logPrefix, 'MemberLeft', memberId);
    });
  }

  @action
  private async setRemoteUsersInfo(): Promise<void> {
    const members = await this.channel.getMembers();
    await Promise.all(_.map(
      async (id) => {
        const user = await this.loadChannelUser(id);
        this.updateChannelUser(user);
      },
      members,
    ));
  }

  @action
  private async setLocalUserInfo(user: ChannelUser): Promise<void> {
    await this.client.setLocalUserAttributes(user);
    const channelUser = await this.loadChannelUser(user.uid);

    this.addChannelUser(channelUser);
  }

  @action
  async startMessaging(options: JoinOptions): Promise<void> {
    ExamsLogger.info(logPrefix, 'Starting messaging: ', options);
    if (this.joined) {
      ExamsLogger.warn(logPrefix, 'Cannot start messaging if you have already joined');
      return;
    }

    this.initClient();

    this.options = options;

    this.initChannel();
    
    await this.client.login({
      uid: _.toString(this.options.uid),
      token: options.token ?? null,
    });

    await this.setLocalUserInfo(this.options.user);

    await this.channel.join();

    await this.setRemoteUsersInfo();

    this.listenClientEvents();
    this.listenChannelEvents();

    runInAction(() => { this.joined = true; });
  }

  @action
  leaveMessaging(): void {
    ExamsLogger.info(logPrefix, 'Leaving messaging: ', this.options);
    if (!this.joined) {
      ExamsLogger.warn(logPrefix, 'Cannot leave messaging if you have not joined');
      return;
    }

    this.client.logout();
    this.channel.leave();

    this.reset();
  }

  @action
  async sendMessageToPeer(message: string, peerId: UID): Promise<void> {
    if (_.isNil(this.client)) {
      ExamsLogger.error(logPrefix, 'Cannot send message to peer if client is null');
      return;
    }
    if (_.isEmpty(message)) return;

    const peer = await this.client?.sendMessageToPeer(
      createTextMessage(message),
      _.toString(peerId),
    );
    if (peer.hasPeerReceived) {
      ExamsLogger.info(logPrefix, 'Message has been recieved by: ', peerId, ' Message: ', message);
      this.addMessage({
        text: message, timestamp: _.now(), senderId: this.options.uid, receiverId: peerId,
      });
    } else {
      ExamsLogger.info(logPrefix, 'Message sent to: ', peerId, ' Message: ', message);
    }
  }

  @action
  async sendMessageToChannel(message: string): Promise<void> {
    if (_.isNil(this.channel)) {
      ExamsLogger.error(logPrefix, 'Cannot send message to channel if channel is null');
      return;
    }
    if (_.isEmpty(message)) return;

    await this.channel?.sendMessage(createTextMessage(message));
    ExamsLogger.info(logPrefix, 'Channel message: ', message, ' from ', this.channel.channelId);
    this.addMessage({
      text: message, timestamp: _.now(), senderId: this.options.uid, receiverId: null,
    });
  }

  @action
  notifyNewMessage(message: ExamsMessage): void {
    const user = this.userStore.user(message.senderId);
    if (_.isEmpty(user)) return;

    this.notifyStore.showMessageNotification(
      `${user.username} sent "${message.text}"`,
      () => this.chatStore.focusChatUser(user.uid),
    );
  }
}

export default RTMStore;
