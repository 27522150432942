import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locale/en.json';
import es from './locale/es.json';
import ja from './locale/ja.json';

type Language = 'en' | 'es' | 'ja'

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  ja: {
    translation: ja,
  },
} as const;

// TODO: Refactor this method
const getLang = (): Language => {
  return 'en';
};

i18n.use(initReactI18next).init({
  lng: getLang(),
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

export default i18n;
