import React from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { ExamCredentials } from "../../../factories";
import Icons from "../../../images/icons";
import { ContextRoom } from "../../../types";
import BottomBarBeforeExam from "../../BottomBar/BottomBarBeforeExam/BottomBarBeforeExam";
import Button from "../../Button/Button";
import Wrapped from "../../SideBar/ParticipantSideBar/ParticipantSideBarBeforeExam/Wrapped/Wrapped";
import ParticipantTopBar from "../../TopBar/ParticipantTopBar/ParticipantTopBar";
import 'star-rating.js/dist/star-rating.css';

const Question = ({ title }) => {
  return (
    <>
      <label
        className="select optional self-center"
        htmlFor={`app_questions_${title}`}
      >
        {title}
      </label>
      <span className="gl-star-rating gl-star-rating--ltr" data-star-rating>
        <select
          className="form-input select optional star-rating"
          name={`app_questions[${title}`}
          id={`app_questions_${title}`}
        >
          <option label=" " />
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
        </select>
        <span className="gl-star-rating--stars" data-rating={0}>
          <span data-index={0} data-value={1}>
            <svg
              viewBox="0 0 32 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ pointerEvents: "none" }}
            >
              <path
                className="gl-star-full e4z-star"
                d="M16 2.14298L20.0059 9.73562L20.2354 10.1705L20.7199 10.2543L29.1788 11.7179L23.1957 17.8741L22.853 18.2267L22.923 18.7134L24.145 27.2106L16.4412 23.4226L16 23.2057L15.5588 23.4226L7.85505 27.2106L9.07703 18.7134L9.14702 18.2267L8.80432 17.8741L2.82119 11.7179L11.2801 10.2543L11.7646 10.1705L11.9941 9.73562L16 2.14298Z"
                stroke="#F2C94C"
                strokeWidth={2}
              />
            </svg>
          </span>
          <span data-index={1} data-value={2}>
            <svg
              viewBox="0 0 32 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ pointerEvents: "none" }}
            >
              <path
                className="gl-star-full e4z-star"
                d="M16 2.14298L20.0059 9.73562L20.2354 10.1705L20.7199 10.2543L29.1788 11.7179L23.1957 17.8741L22.853 18.2267L22.923 18.7134L24.145 27.2106L16.4412 23.4226L16 23.2057L15.5588 23.4226L7.85505 27.2106L9.07703 18.7134L9.14702 18.2267L8.80432 17.8741L2.82119 11.7179L11.2801 10.2543L11.7646 10.1705L11.9941 9.73562L16 2.14298Z"
                stroke="#F2C94C"
                strokeWidth={2}
              />
            </svg>
          </span>
          <span data-index={2} data-value={3}>
            <svg
              viewBox="0 0 32 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ pointerEvents: "none" }}
            >
              <path
                className="gl-star-full e4z-star"
                d="M16 2.14298L20.0059 9.73562L20.2354 10.1705L20.7199 10.2543L29.1788 11.7179L23.1957 17.8741L22.853 18.2267L22.923 18.7134L24.145 27.2106L16.4412 23.4226L16 23.2057L15.5588 23.4226L7.85505 27.2106L9.07703 18.7134L9.14702 18.2267L8.80432 17.8741L2.82119 11.7179L11.2801 10.2543L11.7646 10.1705L11.9941 9.73562L16 2.14298Z"
                stroke="#F2C94C"
                strokeWidth={2}
              />
            </svg>
          </span>
          <span data-index={3} data-value={4}>
            <svg
              viewBox="0 0 32 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ pointerEvents: "none" }}
            >
              <path
                className="gl-star-full e4z-star"
                d="M16 2.14298L20.0059 9.73562L20.2354 10.1705L20.7199 10.2543L29.1788 11.7179L23.1957 17.8741L22.853 18.2267L22.923 18.7134L24.145 27.2106L16.4412 23.4226L16 23.2057L15.5588 23.4226L7.85505 27.2106L9.07703 18.7134L9.14702 18.2267L8.80432 17.8741L2.82119 11.7179L11.2801 10.2543L11.7646 10.1705L11.9941 9.73562L16 2.14298Z"
                stroke="#F2C94C"
                strokeWidth={2}
              />
            </svg>
          </span>
          <span data-index={4} data-value={5}>
            <svg
              viewBox="0 0 32 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ pointerEvents: "none" }}
            >
              <path
                className="gl-star-full e4z-star"
                d="M16 2.14298L20.0059 9.73562L20.2354 10.1705L20.7199 10.2543L29.1788 11.7179L23.1957 17.8741L22.853 18.2267L22.923 18.7134L24.145 27.2106L16.4412 23.4226L16 23.2057L15.5588 23.4226L7.85505 27.2106L9.07703 18.7134L9.14702 18.2267L8.80432 17.8741L2.82119 11.7179L11.2801 10.2543L11.7646 10.1705L11.9941 9.73562L16 2.14298Z"
                stroke="#F2C94C"
                strokeWidth={2}
              />
            </svg>
          </span>
        </span>
      </span>
    </>
  );
};

// TODO: Handle Form & Send to backend
// TODO: map questions from real exam data
export default function Feedback() {
  const { exam, user } = useOutletContext<ContextRoom>();
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-black flex flex-col h-screen">
        <ParticipantTopBar
          examName={exam.name}
          duration={exam.duration}
          takeScreenshot={exam.takeScreenshot}
          uploadFiles={exam.uploadFiles}
          recordEnabled={exam.recordEnabled}
          isUnattended={exam.isUnattended}
        />
        <div className="flex flex-row">
          <Wrapped
            credentials={ExamCredentials}
            exam={exam}
            user={user}
            route="Feedback"
          />
          <div className="h-screen" />
          <BottomBarBeforeExam nextURL="" />
        </div>
      </div>
      <div
        className="fixed z-10 inset-0 overflow-hidden visible bg-opacity-0"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        id="feedback-form-modal"
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity"
            aria-hidden="true"
          ></div>

          <div
            className="flex justify-center mx-auto transition-all duration-500 ease-out"
            id="feedback-form-modal"
          >
            <div className="absolute inset-0 flex items-center justify-center bg-gray-700 bg-dark-transparent">
              <div className="px-12 bg-[#FCFCFD] text-[#23262F] text-center relative">
                <Link to={process.env.REACT_APP_BASE_URL}>
                  <Icons.Close className="absolute right-7 top-7 cursor-pointer w-3" />
                </Link>
                <div className="p-6">
                  <div className="items-center justify-between mt-4 relative">
                    <h1 className="font-bold">Rate your experience</h1>
                    <p className="mt-3">
                      Please rank these factors from a scale of 1-5. 1 = Very
                      Low, 5 = Very High
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <form
                      action=""
                      className="w-full mt-4 mb-9 text-left flex flex-col justify-center items-center"
                    >
                      <div className="border-[#4B506D66] w-full border px-12 pb-8 pt-6 rounded-md">
                        <div className="grid grid-cols-2 gap-4 mt-4 font-bold">
                          {
                            ["Ease of use", "Clarity of instructions", "Level of distractions", "Quality of audio and video"].map(question => {
                              return <Question title={question} />
                            })
                          }
                        </div>
                      </div>
                      <div className="flex justify-end mt-8 w-full px-4 space-x-4">
                        <Button className="btn btn-lg btn-blue borderless">
                          Cancel
                        </Button>
                        <Button onClick={() => window.location.href = process.env.REACT_APP_BASE_URL} className="btn btn-blue btn-md">Submit</Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
