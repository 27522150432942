import React from 'react';

import Logo from '../Logo/Logo';
import ExamName from '../ExamName/ExamName';
import ParticipantToolTip from '../../Tooltip/ParticipantToolTip/ParticipantToolTip';
import Icons from '../../../images/icons';
import HostBusyLabel from '../HostBusyLabel/HostBusyLabel';

type Props = {
  examName: string,
  duration?: number,
  takeScreenshot?: boolean,
  uploadFiles?: boolean,
  recordEnabled?: boolean,
  isUnattended?: boolean,
  isDemo?: boolean,
  showHostBusyLabel?: boolean,
}

const ParticipantTopBar: React.FunctionComponent<Props> = ({
  examName, duration = 0, takeScreenshot = false, uploadFiles = false,
  recordEnabled = false, isUnattended = false, isDemo = false, showHostBusyLabel = false,
}: Props) => (
  <div className="h-10 w-full flex items-center">
    <Logo />
    <div className="px-2 flex flex-grow justify-between">
      <ExamName name={examName} />
      {showHostBusyLabel && <HostBusyLabel />}
      <ParticipantToolTip
        align="text-left"
        offset="{'bottom': 100}"
        tooltipId="tt-topbar"
        duration={duration}
        takeScreenshot={takeScreenshot}
        uploadFiles={uploadFiles}
        recordEnabled={recordEnabled}
        isUnattended={isUnattended}
      >
        <div className="w-5">
          <Icons.SecurityBlue />
        </div>
      </ParticipantToolTip>
    </div>
  </div>
);

export default ParticipantTopBar;
