import _ from 'lodash/fp';
import { action, makeObservable, observable } from 'mobx';

import type { Collapsed } from '../../../types';
import { Resetable } from '../../interfaces/resetable';
import ExamsLogger from '../../../logger';

import UIStore from '.';

const logPrefix = '[Collapsed Store]';

class CollapsedStore implements Resetable {
  uiStore!: UIStore

  @observable
  collapsed: Collapsed = {}

  constructor(ui: UIStore) {
    makeObservable(this);
    this.uiStore = ui;
  }

  isCollapsed(type: keyof Collapsed): boolean {
    return !!(_.get(type, this.collapsed));
  }

  @action
  collapse<K extends keyof Collapsed>(type: K, val: boolean): void {
    ExamsLogger.info(logPrefix, 'Setting collapse: ', type, val);
    this.collapsed = _.set(type, val, this.collapsed);
  }

  @action
  reset(): void {
    this.collapsed = {};
  }
}

export default CollapsedStore;
