import type { UID } from 'agora-rtc-sdk-ng';
import _ from 'lodash/fp';
import {
  action, computed, makeObservable, observable,
} from 'mobx';

import type { ExamsMessage, ExamsUser } from '../../../types';
import RTMStore from '../rtm';
import { Resetable } from '../../interfaces/resetable';
import compare from '../../../lib/compare';

import RoomStore from '.';

class ChatStore implements Resetable {
  roomStore!: RoomStore

  @observable
  unreadMessages: Record<UID, number> = {}

  @observable
  selected: UID | null = null

  constructor(room: RoomStore) {
    makeObservable(this);
    this.roomStore = room;
  }

  @action
  reset(): void {
    this.unreadMessages = {};
    this.selected = null;
  }

  get rtm(): RTMStore {
    return this.roomStore.rtm;
  }

  @computed
  get localUser(): ExamsUser {
    return this.roomStore.localUser;
  }

  @computed
  get sortedMessages(): ExamsMessage[] {
    return _.sortBy('timestamp', this.rtm.messages);
  }

  @computed
  get channelMessages(): ExamsMessage[] {
    return _.filter((msg) => _.isNil(msg.receiverId), this.sortedMessages);
  }

  directMessages(uid: UID): ExamsMessage[] {
    return _.filter((msg) => {
      const sentToMe = compare.equalAgoraUID(msg.senderId, uid)
        && compare.equalAgoraUID(msg.receiverId, this.localUser.uid);
      const sentByMe = compare.equalAgoraUID(msg.senderId, this.localUser.uid)
        && compare.equalAgoraUID(msg.receiverId, uid);
      return sentToMe || sentByMe;
    }, this.sortedMessages);
  }

  @computed
  get hostMessagesToMe(): ExamsMessage[] {
    const { host } = this.roomStore;

    const direct = this.directMessages(host.uid);
    const hostMessages = _.filter(
      (msg) => compare.equalAgoraUID(msg.senderId, host.uid),
      this.channelMessages,
    );
    return _.sortBy('timestamp', _.concat(direct, hostMessages));
  }

  @action
  setSelected(uid: UID | null): void {
    this.selected = uid;
  }

  @action
  async sendMessageToChannel(message: string): Promise<void> {
    await this.rtm.sendMessageToChannel(message);
  }

  @action
  async sendMessageToPeer(message: string, uid: UID): Promise<void> {
    await this.rtm.sendMessageToPeer(message, uid);
  }

  @computed
  get totalUnreadMessages(): number {
    return _.sum(_.values(this.unreadMessages));
  }

  @computed
  get anyUnreadMessage(): boolean {
    return this.totalUnreadMessages > 0;
  }

  userUnreadMessages(uid: UID): number {
    return _.toInteger(_.get(uid, this.unreadMessages));
  }

  hasUnreadMessages(uid: UID): boolean {
    return this.userUnreadMessages(uid) > 0;
  }

  @action
  clearUnreadMessages(uid: UID): void {
    this.unreadMessages = _.set(uid, 0, this.unreadMessages);
  }

  @action
  increaseUnreadMessages(uid: UID): void {
    const unread = this.userUnreadMessages(uid);
    this.unreadMessages = _.set(uid, unread + 1, this.unreadMessages);
  }

  @action
  focusInput(): void {
    this.roomStore.appStore.uiStore.collapsed.collapse('SIDEBAR', false);
    document.getElementById('chat-input')?.focus();
  }

  @action
  focusChatUser(uid: UID): void {
    this.setSelected(uid);
    this.roomStore.setActiveTab('chat');
    this.clearUnreadMessages(uid);
  }
}

export default ChatStore;
