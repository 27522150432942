import React, { useState } from "react";
import ReactTooltip from "react-tooltip";

type Props = React.PropsWithChildren<{
  place: string;
  multiline?: boolean;
  backgroundColor?: string;
  offset?: string;
  content: JSX.Element;
  event?: string;
  eventOff?: string;
  className?: string;
  tooltipId?: string;
}>;

const Tooltip: React.FunctionComponent<Props> = ({
  place,
  multiline = true,
  backgroundColor = "#454545",
  offset,
  content,
  event,
  tooltipId,
  children,
  className,
  eventOff,
}: Props) => {
  const [tooltip, showTooltip] = useState(true);

  return (
    <div>
      <div
        data-for={tooltipId}
        data-class={className}
        data-place={place}
        data-multiline={multiline}
        data-background-color={backgroundColor}
        data-offset={offset}
        data-tip=""
        data-event={event}
        data-event-off={eventOff}
        onMouseEnter={() => showTooltip(true)}
        onMouseLeave={() => {
          showTooltip(false);
          setTimeout(() => showTooltip(true), 50);
        }}
      >
        {children}
      </div>
      {tooltip && (
        <ReactTooltip
          id={tooltipId || ""}
          globalEventOff="click"
          arrowColor="transparent"
          effect="solid"
          delayHide={1000}
          clickable
        >
          {content}
        </ReactTooltip>
      )}
    </div>
  );
};

export default Tooltip;