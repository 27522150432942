import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';

import '../../../services/i18n/config';
import RedButton from '../../Button/RedButton/RedButton';
import Reset from '../Reset/Reset';
import Mute from '../Mute/Mute';
import Chat from '../Chat/Chat';
import Recording from '../Recording/Recording';
import Snapshot from '../Snapshot/Snapshot';
import SpeakTo from '../SpeakTo/SpeakTo';
import TimeLeft from '../TimeLeft/TimeLeft';
import { useModalStore, useRecordStore, useRoomStore } from '../../../hooks/store';
import examService from '../../../services/exam.service';
import BlueButton from '../../Button/BlueButton/BlueButton';
import Comment from '../Comment/Comment';

const HostBottomBar: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const roomStore = useRoomStore();
  const modalStore = useModalStore();
  const recordStore = useRecordStore();
  const [starting, setStarting] = useState(false);

  const onClickEndExam = async () => {
    // const data = await examService.get(roomStore.info.exam.id);

    modalStore.openModal('END_EXAM', {
      examName: roomStore.examName,
      finishedParticipantsCount: 1,
      incidences: [
        roomStore.incidenceStore.uniqueIncidencesTriggered,
        roomStore.incidenceStore.participantsPerIncidence,
      ],
      onRestart: async () => {
        await roomStore.startExam();
      },
      onEnd: async () => {
        await roomStore.endExam();
        // await examService.finish(roomStore.info.exam.id);
        window.location.href = process.env.REACT_APP_BASE_URL;
      },
    });
  };

  const onClickStartExam = async () => {
    setStarting(true);
    await recordStore.startRecording();
    await roomStore.startExam();
    setStarting(false);
  };

  return (
    <div className="flex flex-row w-full justify-between">
      <div className="flex flex-row space-x-8 ">
        <SpeakTo />
      </div>
      <div className="flex flex-row space-x-12 ">
        <Mute />
        <Reset />
        <Chat />
        <Comment />
        {roomStore.isRecordingEnabled && <Recording />}
        <Snapshot />
      </div>
      <div className="flex flex-row items-center space-x-6">
        <TimeLeft />
        {!_.isNil(roomStore.endTime) ? (
          <div data-test-id="end_button">
            <RedButton
              className="whitespace-nowrap text-lg"
              onClick={onClickEndExam}
            >
              {t('end_restart_session')}
            </RedButton>
          </div>
        ) : (
          <div data-test-id="start_button">
            <BlueButton
              disabled={starting}
              className="whitespace-nowrap text-lg"
              onClick={onClickStartExam}
            >
              {t('start_session')}
            </BlueButton>
          </div>
        )}
      </div>
    </div>
  );
});

export default HostBottomBar;
